import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "../Header"
import styles from "../style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line } from '@ant-design/plots';
import { Table, Spin } from "antd"
import getDates from "../getDates"
import { DataGrid } from '@mui/x-data-grid';
import "../scss/report.scss"
import StorageService from "../../../utils/storage.service";
const ReportBusiness = () => {
    const columns = [
        { dataIndex: 'date', title: 'Thời gian', width: 150  },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            dataIndex: 'totalPrice',
            title: 'Doanh số Thuần',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
        },
        {
            dataIndex: 'paid',
            title: 'Tổng tiền thực thu',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) =>Number(a.paid) - Number(b.paid),
                multiple: 1,
            },
        },
        {
            dataIndex: 'countOrderNew',
            title: 'SL hoá đơn',
            // width: 50 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        {
            dataIndex: 'proportion',
            title: 'Tỉ lệ đơn/tổng đơn',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{data} %</p>
                )
            },
        },
        {
            dataIndex: 'countService',
            title: 'Sl dịch vụ bán',
            // width: 50 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            dataIndex: 'aov',
            title: 'AOV',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT Triệt',
            align: "start",
            // width: 200 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền: {convertCurrency(data.paidTriet)}</p>
                        <p className="text2">Đơn: {data.countTriet}</p>
                        <p className="text3">Aov: {convertCurrency(data.aovTriet)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countDa',
            title: 'TT Da',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền: {convertCurrency(data.paidDa)}</p>
                        <p className="text2">Đơn: {data.countDa}</p>
                        <p className="text3">Aov: {convertCurrency(data.aovDa)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countDa',
            title: 'TT CS Body',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền: {convertCurrency(data.paidBody)}</p>
                        <p className="text2">Đơn: {data.countBody}</p>
                        <p className="text3">Aov: {convertCurrency(data.aovBody)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countKhac',
            title: 'TT khác',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền: {convertCurrency(data.paidKhac)}</p>
                        <p className="text2">Đơn: {data.countKhac}</p>
                        <p className="text3">Aov: {convertCurrency(data.aovKhac)}</p>
                    </div>
                )
            },
        },
    ];
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [sortBy, setSortBy] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalOrderNew, setTotalOrderNew] = useState(0)
    const [totalOrderTriet, setTotalOrderTriet] = useState(0)
    const [totalOrderBody, setTotalOrderBody] = useState(0)
    const [totalOrderDa, setTotalOrderDa] = useState(0)
    const [totalPaidTriet, setTotalPaidTriet] = useState(0)
    const [totalPaidBody, setTotalPaidBody] = useState(0)
    const [totalPaidDa, setTotalPaidDa] = useState(0)
    const [totalPaidKhac, setTotalPaidKhac] = useState(0)
    const [ordersByDaysCountBooking, setOrdersByDaysCountBooking] = useState([])
    const [totalKhac, setTotalKhac] =   useState('0%')
    const [totalPrice, setTotalPrice] = useState(0)
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const hanldeRangeDate = useCallback((sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }, [startDate, endDate])
    const [pageSize, setPageSize] = useState(5)

    const hanldeStore = useCallback((e) => {
        setStore(e)
    }, [store])

    const hanldeSortBy = useCallback((e) => {
        setSortBy(e)
    }, [sortBy]);

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate, sortBy)
    }, [store, endDate, startDate, sortBy])
    
    const fetchData = async (s, eD, sD, sBy) => {
        const rs = await axiosService(`api/reports/store/work/business/${s}?storeId=${s}&startDate=${sD}&endDate=${eD}&sortBy=${sBy}`, "GET")
        const { data } = rs.data
        if (rs.status == 200) {
            setTotalPaid(data.totalPaid)
            setTotalOrderNew(data.totalOrderNew)
            setTotalOrderTriet(data.totalOrderTriet)
            setTotalOrderDa(data.totalOrderDa)
            setTotalOrderBody(data.totalOrderBody)
            setTotalKhac(data.totalKhac)
            setTotalPaidTriet(data.totalPaidTriet)
            setTotalPaidBody(data.totalPaidBody)
            setTotalPaidDa(data.totalPaidDa)
            setTotalPaidKhac(data.totalPaidKhac)
            setTotalPrice(data.totalPrice)
            
            setRows(data.dataTable)

            let rowsCountBooking = []
            let rowsCountBookingSuccess = []
            let rowsCountBookingService = []
            for (let item of data.dataTable.reverse()) {
                let rowCountBooking = {
                    date: item.date,
                    value: item.paid,
                    type: 'Tiền thực thu'
                }
                let rowCountBookingSuccess = {
                    date: item.date,
                    value: item.countOrderNew,
                    type: 'Đơn hàng mới'
                }
                let rowCountBookingService = {
                    date: item.date,
                    value: item.aov,
                    type: 'Aov'
                }
                rowsCountBooking.push(rowCountBooking)
                rowsCountBookingSuccess.push(rowCountBookingSuccess)
                rowsCountBookingService.push(rowCountBookingService)
            }
            setOrdersByDaysCountBooking([...rowsCountBooking, ...rowsCountBookingSuccess, ...rowsCountBookingService])
            let value = "yyyy-MM-dd"
                if (sortBy === 1) {
                    value = "ww"
                } else if (sortBy === 2) {
                    value = "MM"
                }
            setLoading(false)
        }
    
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const config = useMemo(() => configChart(ordersByDaysCountBooking, convertCurrency), [ordersByDaysCountBooking])
    
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const  handleChange = (e) => {
        setPageSize(e.pageSize)
    }
    

    return (
        <div className={`${styles.background} report-page`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                sortBy={sortBy} hanldeSortBy={hanldeSortBy}
                title={"Kinh doanh cơ sở"} type={"daily"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thời gian', key: "date" },
                        { label: "Số lượng lịch đặt", key: "count_booking" },
                        { label: "Số lượng lịch huỷ", key: "count_close" },
                        { label: "Số lượng lịch thành công", key: "count_success" },
                        { label: "Tỉ lệ huỷ/tổng lịch đặt", key: "proportion" },
                        { label: "Số lượng khách hàng phục vụ", key: "count_customer" },
                        { label: "Lượt dịch vụ", key: "count_service" },
                    ]
                }
                userAdmin={userAdmin}
            />
            <div className="container-fluid">
                <Spin spinning={loading}>
                    <Row className="mt-2">
                        <Col xs={12} md={12}>
                            <Row className="h-100">
                                <Col xs={6} md={2}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Tổng tiền thực thu: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>SL Hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaid)}</span>
                                                <span className={styles.textTag2}> {totalOrderNew}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag4}>Doanh số Thuần: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPrice)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>

                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Triệt: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>SL Hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidTriet)}</span>
                                                <span className={styles.textTag2}> {totalOrderTriet}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Da: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>SL Hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidDa)}</span>
                                                <span className={styles.textTag2}> {totalOrderDa}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Body: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>SL Hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidBody)}</span>
                                                <span className={styles.textTag2}> {totalOrderBody}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu dịch vụ Khác: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>SL Hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidKhac)}</span>
                                                <span className={styles.textTag2}> {totalKhac}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            
                            
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={12} md={5} className="hide-mobile">
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <Line {...config} />
                            </div>
                        </Col>
                        <Col xs={12} md={7}>
                            <div style={{ backgroundColor: "white" }} >
                                <div className="border-bottom mb-1 p-2">
                                    <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                                </div>
                                <div style={{ width: '100%' }} className="p-2">
                                    <Table 
                                        columns={columns} 
                                        dataSource={rows}
                                        pagination={{ pageSize: pageSize, showSizeChanger: true, pageSizeOptions: ['5', '15', '30', '50', '100'] }}
                                        scroll={{ x: 1200 }}
                                        onChange={handleChange}
                                        bordered
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div >

    )
}
const configChart = (data, convertCurrency) => {  
    var config = {
        data: data,
        xField: "date",
        yField: "value",
        seriesField: "type",
        height: 520,
        xAxis: {
          title: {
            text: "Kinh Doanh",
            position: "end",
            offset: 0,
            spacing: 8,
            style: {
              fontSize: 13,
              fontWeight: 900,
              textAlign: "start"
            }
          },
          line: {
            style: {
              stroke: "black"
            }
          },
          tickLine: {
            style: {
              stroke: "black"
            }
          },
          label: {
            autoRotate: true,
            style: {
              fill: "black",
            //   fontSize: 10
            },
            formatter: (text) => {
              const oldLabel = text;
              const labelLength = oldLabel.replace(/[^x00-xff]/g, "xx").length;
              let newLabel = "";
              if (labelLength > 12) {
                let strLen = 0;
                let firstStr = "";
                let lastStr = "";
                for (let i = 0; i < labelLength; i++) {
                  if (oldLabel.charCodeAt(i) > 128) {
                    strLen += 2;
                  } else {
                    strLen++;
                  }
                  if (strLen <= 12) {
                    firstStr += oldLabel.charAt(i);
                  } else {
                    lastStr += oldLabel.charAt(i);
                  }
                }
                newLabel = `${firstStr}\n${lastStr}`;
              } else {
                newLabel = oldLabel;
              }
              return newLabel;
            }
          }
        },
        yAxis: {
          line: {
            style: {
              lineWidth: 2
            }
          },
          label: {
            style: {
              fill: "black"
            },
            offset: 15,
            formatter: (text) => _.round(_.divide(text, 1), 2).toLocaleString()
          }
        },
        color: ["#673ab7", "#2196f3", "#4caf50"],
        legend: {
          position: "right",
          marker: {
            symbol: "square"
          },
          itemName: {
            formatter: (text, item, index) => {
    
              return '';
            }
          }
        },
        point: [{
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#673ab7',
                lineWidth: 2,
            },
        }, {
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#2196f3',
                lineWidth: 2,
            },
        },
        {
            size: 3,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#4caf50',
                lineWidth: 2,
            },
        }],
        tooltip: {
            customContent: (count, data) => {
                let fileHtml = ''
                let title = ''
                for(let item of data) {
                    title = item.title
                    let name = item.name
                    let value = item.value
                    if (name != "Đơn hàng mới") {
                        value = convertCurrency(item.value)
                    }
                    fileHtml += '<li class="g2-tooltip-list-item">'
                    fileHtml += '<span class="g2-tooltip-marker" style="background: '+item.color+'; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;">' + '</span>'
                    fileHtml += '<span class="g2-tooltip-name" style="color: '+item.color+';">'+item.name + '</span>'
                    fileHtml += '<span class="g2-tooltip-value" style="color: '+item.color+';">'+ value + '</span>'
                    fileHtml += '</li>'
                }
                return `<div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px; padding-top: 12px; font-weight: bold; font-size: 15px"><p> ${title} </div>
                    <ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 12px !important; ">
                        ${fileHtml}
                        </br>
                    </ul>
                </div>`;
            }
        },
      };
    return config
}
export default ReportBusiness