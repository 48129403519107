export const dataUpgrade = {
  // "696-826": [
  //   // Combo Nách + 5b dưỡng trắng
  //   {
  //     value: "825",
  //     label: "Triệt - 8 Vùng - 10 buổi",
  //   },
  //   {
  //     value: "803",
  //     label: "Triệt lông Premium - 10 buổi",
  //   },
  // ],
  // "92-827": [
  //   // Combo Bikini + 5b trẻ hóa
  //   {
  //     value: "825",
  //     label: "Triệt - 8 Vùng - 10 buổi",
  //   },
  //   {
  //     value: "803",
  //     label: "Triệt lông Premium - 10 buổi",
  //   },
  // ],
};
