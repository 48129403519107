import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin, Button } from 'antd';
import Loading from "../customer/Loading";

import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import PopupViewServices from "./PopupViewServices"

const ViewDetailServiceTvv = (props) => {
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)
    const [pageSize, setPageSize] = useState(10)

    
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
   

    const columns = [
        {
            title: 'Ngày',
            dataIndex: 'date',
            // align: "center",
            width: 150,
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Nâng cấp Triệt lông Premium',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__1)
                let total_paid = Number(data.paidUpPremium)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Triệt lông Premium 08 vùng',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__2)
                let total_paid = Number(data.paidPremium08)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Triệt lông Premium Fullbody',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__3)
                let total_paid = Number(data.paidPremiumFullbody)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Đầu triệt cá nhân',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__4)
                let total_paid = Number(data.paidDauTriet)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Cent Upgrade',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__5)
                let total_paid = Number(data.paidCentUpgrade)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Triệt lông theo vùng',
            // align: "center",
            with: 150,
            render: (data) => {
                let total_taget = Number(data.target__6)
                let total_paid = Number(data.paidTheoVung)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Chăm sóc Body',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__7)
                let total_paid = Number(data.paidBody)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },
        {
            title: 'Chăm sóc Da',
            // align: "center",
            render: (data) => {
                let total_taget = Number(data.target__8)
                let total_paid = Number(data.paidDa)
                let tiLe = '0'
                if (total_taget > 0 && total_paid > 0) {
                    tiLe = ((total_taget/total_paid)*100).toFixed(1)
                }
                return (
                    <div>
                        <p className="text7">Mục tiêu: {convertCurrency(total_taget)}</p>
                        <p className="text1">Kết quả: {convertCurrency(total_paid)}</p>
                        <p className="text3">Tỉ lệ: {tiLe}%</p>
                    </div>
                )
            },
        },

    ];
    const handelExportUser = () => {
        // if (_state.loadingShowClickItem) return
        setShowloading(true)
      
        var url = `api/mysql/export/detail-user?type=2&user_id=${props.userId}&user_name=${props.userName}&start=${props.startDate}&end=${props.endDate}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
  
    const  handleChange = (e) => {
        setPageSize(e.pageSize)
    }

    return (
        <>
            <div style={{ backgroundColor: "white" }} className="p-2">
                <div className="border-bottom w-100 mb-2">
                    <Row>
                        <Col xs={6}>
                            <div>
                                Bảng số liệu chi tiết
                            </div>
                        </Col>
                        <Col xs={6}>
                            <Button size="middle" type="primary"
                            onClick={handelExportUser}
                            ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Table 
                    columns={columns} 
                    dataSource={props.table}
                    pagination={{ pageSize: pageSize, showSizeChanger: true, pageSizeOptions: ['10', '15', '30', '50', '100'] }}
                    scroll={{ x: columns.length*150 }}
                    onChange={handleChange}
                    bordered
                />
            </div>
            <Loading
            show={showloading}
             ></Loading>
        </>
    )
}
export default ViewDetailServiceTvv