import { useState , useLayoutEffect} from "react";
import axiosService from "../utils/axios.service";
import CurrencyInput from 'react-currency-input-field';
import "./daily.scss";
import StorageService from "../utils/storage.service";
import { notifications } from "./constant/Notify";
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    TreeSelect,
    Table
  } from 'antd';


export function ModalConfirmDaily(props) {
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isEdit, setEdit] = useState(false)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))

    const [targetUpPremium, setTargetUpPremium] = useState(0)
    const [confidentUpPremium, setConfidentUpPremium] = useState(0)

    const [targetPremium08, setTargetPremium08] = useState(0)
    const [confidentPremium08, setConfidentPremium08] = useState(1)

    const [targetPremiumFulbody, setTargetPremiumFulbody] = useState(0)
    const [confidentPremiumFulbody, setConfidentPremiumFulbody] = useState(0)

    const [targetDauTriet, setTargetDauTriet] = useState(0)
    const [confidentDauTriet, setConfidentDauTriet] = useState(0)

    const [targetCentUpgrade, setTargetCentUpgrade] = useState(0)
    const [confidentCentUpgrade, setConfidentCentUpgrade] = useState(0)

    const [targetTrietTheoVung, setTargetTrietTheoVung] = useState(0)
    const [confidentTrietTheoVung, setConfidentTrietTheoVung] = useState(0)

    const [targetBody, setTargetBody] = useState(0)
    const [confidentBody, setConfidentBody] = useState(0)

    const [targetDa, setTargetDa] = useState(0)
    const [confidentDa, setConfidentDa] = useState(0)

    const [dataSource, setDataSource] = useState([])

    const [slectColor1, setSlectColor1] = useState('color-default')
    const [slectColor2, setSlectColor2] = useState('color-default')
    const [slectColor3, setSlectColor3] = useState('color-default')
    const [slectColor4, setSlectColor4] = useState('color-default')
    const [slectColor5, setSlectColor5] = useState('color-default')
    const [slectColor6, setSlectColor6] = useState('color-default')
    const [slectColor7, setSlectColor7] = useState('color-default')
    const [slectColor8, setSlectColor8] = useState('color-default')

    const [tageMonth, setTageMonth] = useState(0)
    const [monthTotalPaid, setMonthTotalPaid] = useState(0)
    const selectTarget = [
        { value: 0, label: 'Lựa chọn', color: 'color-default'},
        { value: 1, label: 'Sẽ vượt mục tiêu', color: 'color-default-1'},
        { value: 2, label: 'Tự tin đạt mục tiêu', color: 'color-default-2' },
        { value: 3, label: 'Chưa tự tin đạt', color: 'color-default-3' },
    ]
  
    var acceptCancelOrder = (event) => {
        const data = {
            "user_id": userAdmin.id,
            "user_name": userAdmin.name,
            "meta": [
                {meta_key: 1, confident_number: confidentUpPremium, target: targetUpPremium, confident: selectTarget[confidentUpPremium].label, service: "Nâng cấp Triệt lông Premium"},
                {meta_key: 2, confident_number: confidentPremium08, target: targetPremium08, confident: selectTarget[confidentPremium08].label, service: "Triệt lông Premium 08 vùng"},
                {meta_key: 3, confident_number: confidentPremiumFulbody, target: targetPremiumFulbody, confident: selectTarget[confidentPremiumFulbody].label, service: "Triệt lông Premium Fullbody"},
                {meta_key: 4, confident_number: confidentDauTriet, target: targetDauTriet, confident: selectTarget[confidentDauTriet].label, service: "Đầu triệt cá nhân"},
                {meta_key: 5, confident_number: confidentCentUpgrade, target: targetCentUpgrade, confident: selectTarget[confidentCentUpgrade].label, service: "Cent Upgrade"},
                {meta_key: 6, confident_number: confidentTrietTheoVung, target: targetTrietTheoVung, confident: selectTarget[confidentTrietTheoVung].label, service: "Triệt lông theo vùng"},
                {meta_key: 7, confident_number: confidentBody, target: targetBody, confident: selectTarget[confidentBody].label, service: "Chăm sóc Body"},
                {meta_key: 8, confident_number: confidentDa, target: targetDa, confident: selectTarget[confidentDa].label, service: "Chăm sóc Da"}
            ]
        }
        let check = 0
        for (let item of data["meta"]) {
            if (item.target > 0 && item.confident_number > 0) {
                check ++
            }
        }

        if (check < 2) {
            notifications({ type: 'error', mess: 'Ít nhất 02 mục tiêu trong ngày hôm nay'});
            return
        }
        
        axiosService("api/daily-target","POST", data)
            .then((res)=> {
                const response = res.data;
                notifications({ type: 'success', mess: 'Tạo báo cáo đầu ngày thành công'});
            })
        
            setEdit(false)
    }
    var hiddenPopup = () => {
        props.setShow(false)
    }
    useLayoutEffect(() => {
        fetchData()
    }, [])

    const fetchData = async() => {
        // if (userAdmin.role != 2) {
        //     return
        // }
        const rs = await axiosService(`api/daily-target/` + userAdmin.id, "GET")
        const { data } = rs.data
        let check = data.table[0]
            // console.log(data.table[0])
        let dataSource = []
        if (check != undefined) {
            dataSource = [
                {
                  key: '1',
                  name: 'Nâng cấp Triệt lông Premium',
                  age: convertCurrency(check.target__1),
                  address: convertCurrency(check.paidUpPremium),
                  color: check.target__1 < check.paidUpPremium ? 'text10' : 'text2'
        
                },
                {
                  key: '2',
                  name: 'Triệt lông Premium 08 vùng',
                  age: convertCurrency(check.target__2),
                  address: convertCurrency(check.paidPremium08),
                  color: check.target__2 < check.paidPremium08 ? 'text10' : 'text2'
        
                },
                {
                    key: '3',
                    name: 'Triệt lông Premium Fullbody',
                    age: convertCurrency(check.target__3),
                    address: convertCurrency(check.paidPremiumFullbody),
                    color: check.target__3 < check.paidPremiumFullbody ? 'text10' : 'text2'
        
                  },
                  {
                    key: '4',
                    name: 'Đầu triệt cá nhân',
                    age: convertCurrency(check.target__4),
                    address: convertCurrency(check.paidDauTriet),
                    color: check.target__4 < check.paidDauTriet ? 'text10' : 'text2'
        
                  },
                  {
                    key: '5',
                    name: 'Cent Upgrade',
                    age: convertCurrency(check.target__5),
                    address: convertCurrency(check.paidCentUpgrade),
                    color: check.target__5 < check.paidCentUpgrade ? 'text10' : 'text2'
        
                  },
                  {
                    key: '6',
                    name: 'Triệt lông theo vùng',
                    age: convertCurrency(check.target__6),
                    address: convertCurrency(check.paidTheoVung),
                    color: check.target__6 < check.paidTheoVung ? 'text10' : 'text2'
        
                  },
                  {
                    key: '7',
                    name: 'Chăm sóc Body',
                    age: convertCurrency(check.target__7),
                    address: convertCurrency(check.paidBody),
                    color: check.target__7 < check.paidBody ? 'text10' : 'text2'
        
                  },
                  {
                    key: '8',
                    name: 'Chăm sóc Da',
                    age: convertCurrency(check.target__8),
                    address: convertCurrency(check.paidDa),
                    color: check.target__8 < check.paidDa ? 'text10' : 'text2'
                  },
        
                    ];
        }
            setDataSource([...dataSource])
            setTageMonth(data.tageMonth)
            setMonthTotalPaid(data.totalPaid)
        console.log(userAdmin)
        if (userAdmin.role == 2 && !data.check && userAdmin.id != 2) {
            setEdit(true)
        }
        //setEdit(true)

    }

    const columns = [
    {
        title: 'Dịch vụ',
        dataIndex: 'name',
        width: '50%',
    //   align: "center",
        key: 'name',
    },
    {
        title: 'Mục tiêu',
        dataIndex: 'age',
        width: '25%',
        align: "center",
        render: (data) => {
            return (
                <p className="text3">{data}</p>
            )
        },
    },
    {
        title: 'Kết quả',
        // dataIndex: 'address',
        width: '25%',
        align: "center",
        render: (data) => {
            return (
                <p className={data.color}>{data.address}</p>
            )
        },
    },
    ];

    const onChangeUpPremium = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetUpPremium(Number(data))
    }
    const onChangePremium08 = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetPremium08(Number(data))
    }
    const onChangePremiumFulbody = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetPremiumFulbody(Number(data))
    }
    const onChangeDauTriet = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetDauTriet(Number(data))
    }
    const onChangeCentUpgrade = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetCentUpgrade(Number(data))
    }
    const onChangeTrietTheoVung = (e) => {
        let data = e.target.value.replaceAll(",", "");
        setTargetTrietTheoVung(Number(data))
    }
    const onChangeBody = (e) => {
        let data = e.target.value.replaceAll(",", "");
    
        setTargetBody(Number(data))
    }
    const onChangeDa = (e) => {
        let data = e.target.value.replaceAll(",", "");
      
        setTargetDa(Number(data))
    }

    const onChangeConfidentUpPremium = (e) => {
        setSlectColor1(selectTarget[e].color)
        setConfidentUpPremium(e)
    }
    const onChangeConfidentPremium08 = (e) => {
        setSlectColor2(selectTarget[e].color)
        setConfidentPremium08(e)
    }
    const onChangeConfidentPremiumFulbody = (e) => {
        setSlectColor3(selectTarget[e].color)
        setConfidentPremiumFulbody(e)
    }
    const onChangeConfidentDauTriet = (e) => {
        setSlectColor4(selectTarget[e].color)
        setConfidentDauTriet(e)
    }
    const onChangeConfidentCentUpgrade = (e) => {
        setSlectColor5(selectTarget[e].color)
        setConfidentCentUpgrade(e)
    }
    const onChangeConfidentTrietTheoVung = (e) => {
        setSlectColor6(selectTarget[e].color)
        setConfidentTrietTheoVung(e)
    }
    const onChangeConfidentBody = (e) => {
        setSlectColor7(selectTarget[e].color)
        setConfidentBody(e)
    }
    const onChangeConfidentDa = (e) => {
        setSlectColor8(selectTarget[e].color)
        setConfidentDa(e)
    }

    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
   
    return (
        isEdit ? 
        <div className="order-popup daily-confirm">
            <div className="popup-cent">
                <div className="content-popup fadeInDown">
                    <div className="head-popup">
                        <div className="title">BÁO CÁO ĐẦU NGÀY <span></span> </div>
                    </div>
                    <div className="body-popup">
                        <div className= "items-data " style={{lineHeight: '25px'}}>
                            <div className="title title-momth">Mục tiêu tháng: <span className="text7">{convertCurrency(tageMonth)}</span></div>
                            <div className="title title-momth">Kết quả tháng: <span className="text1">{convertCurrency(monthTotalPaid)}</span></div>
                        </div>     
                        <div className="form-check-cent">
                            <div className="title-body">1. Kết quả ngày hôm qua</div>
                            <Table dataSource={dataSource} columns={columns} pagination={false}/>
                            <br/>
                            <div className="title-body">2. Đăng ký mục tiêu ngày hôm nay</div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Nâng cấp Triệt lông Premium</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeUpPremium} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetUpPremium}   />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor1}  onChange={onChangeConfidentUpPremium} defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Triệt lông Premium 08 vùng</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangePremium08} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetPremium08}   />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor2}   onChange={onChangeConfidentPremium08}  defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Triệt lông Premium Fullbody</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangePremiumFulbody} groupSeparator={','}
                                                decimalSeparator={'.'}  defaultValue={targetPremiumFulbody}  />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor3}   onChange={onChangeConfidentPremiumFulbody} defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Đầu triệt cá nhân</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeDauTriet} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetDauTriet}   />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor4}  onChange={onChangeConfidentDauTriet}  defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Cent Upgrade</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeCentUpgrade} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetCentUpgrade}  />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor5}  onChange={onChangeConfidentCentUpgrade}  defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Triệt lông theo vùng</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeTrietTheoVung} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetTrietTheoVung}  />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor6}   onChange={onChangeConfidentTrietTheoVung} defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Chăm sóc Body</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeBody} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetBody}  />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor7}  onChange={onChangeConfidentBody} defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                                <div className="wp-list">
                                    <div className= "items-data title-items-data">
                                        <div className="title">Chăm sóc Da</div>
                                    </div>

                                    <div className= "items-data row">
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mục tiêu:</div>
                                        <div className="value col-6  select-option-one">
                                            <CurrencyInput onChange={onChangeDa} groupSeparator={','}
                                                decimalSeparator={'.'} defaultValue={targetDa} />
                                        </div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-1 "></div>
                                        <div className="title col-4 ">Mức tự tin:</div>
                                        <div className="value col-6  select-option-one">
                                            <Select className={slectColor8}  onChange={onChangeConfidentDa}  defaultValue={selectTarget[0]} options={selectTarget} />
                                        </div>
                                    </div>
                                </div>
                            <br/>
                        </div>
                    </div>
                    <div className="bottom-popup">
                        <div className="button-container">
                            {/* <div className="button-cent style3" onClick={hiddenPopup} >
                                <span><i className="fas fa-times" ></i></span>Không
                            </div> */}
                            <div className="button-cent" onClick={acceptCancelOrder}>
                                <span><i className="fas fa-save"></i></span>Đăng Ký
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
            </div>
        </div>
        : null
    )
}