import React from "react";
import Dashboard from "./components/Dashboard";
import Stores from "./components/stores/Stores";
import Services from "./components/services/Services";
import Product from "./components/product/Product";
import Operators from "./components/operators/Operators";
// import Booking from "./components/booking/Booking";
import Customer from "./components/customer/Customer";
import Pos from "./components/pos/Pos";
import Orders from "./components/orders/Orders";
import MemberCards from "./components/membercards/MemberCards";
import Cards from "./components/cards/Cards"
import ReportDaily from "./components/report/ReportDaily"
import ReportLocation from "./components/report/ReportLocations";
import ReportCustomer from "./components/report/ReportCustomer"
import ReportCashier from "./components/report/ReportCashier"
import ReportUser from "./components/report/ReportUser";
import ReportProduct from "./components/report/ReportProduct";
import PreBooking from "./components/pre-booking";
import PreOrder from "./components/pre-order";
import Voucher from "./components/voucher";
import Emulation from "./components/emulation";

import ChangePassword from "./components/ChangePassword";
import ReportSales from "./components/report/ReportSales";
import ReportTvOnline from "./components/report/ReportTvOnline";
import ReportLtOnline from "./components/report/ReportLtOnline";
import ReportKtvTvvOffline from "./components/report/ReportKtvTvvOffline";
import ReportKtvTvvOfflineDetail from "./components/report/ReportKtvTvvOfflineDetail";
import ReportServiceDetail from "./components/report/ReportServiceDetail";
import IncomeFunction from "./components/report/Receipt"
import PromotionLocation from "./components/report/Promotion"
import DetailPromotion from "./components/report/DetailPromotion"
import UpdateVoucher from "./components/voucher/Update"
import Protected from "./middleware/authorize";
import TakeVoucher from "./components/TakeVoucher"
import GroupVoucher from "./components/voucher/GroupVoucher"
import SkinVoucher from "./components/voucher/SkinVoucher"
import Strapi from "./components/Strapi"
import ReportByDay from "./components/report-by-day/Index";
import DetailQrCode from "./components/report/DetailQrCode"
import CustomerCountPackageBooking from "./components/report/CustomerCountPackageBooking"
import ListDataSaleOnline from "./components/data-sale-online/ListDataSaleOnline";

import ReportOperateBooking from "./components/report/more/ReportOperateBooking"
import ReportBusiness from "./components/report/more/ReportBusiness"
import ReportKtvTvDetails from "./components/report/more/ReportKtvTvDetails" // tổng thế nhân viên
import ReportKtvTvvDetail from "./components/report/more/ReportKtvTvvDetail" // chi từng nhân viên
import ReportStoreBooingPrice from "./components/report/more/ReportStoreBooingPrice" // chi từng nhân viên
import ReportSaleTvCtm from "./components/report/ReportSaleTvCtm" // chi từng nhân viên
import ReportEmulation from "./components/report/ReportEmulation" // List thi đua
import ReportCustomerBusiness from "./components/report/ReportCustomerBusiness" // kinh doanh dịch vụ phễu


// import ReportByKTV from "./components/report-by-day/ReportByKTV";

const routes = [
  {
    path: "/dashboard",
    exact: false,
    main: () => <Dashboard />,
  },
  {
    path: "/take-voucher",
    exact: false,
    main: () => <TakeVoucher />,
  },
  {
    path: "/create-voucher-lv3",
    exact: false,
    main: () => <Protected permissions={[1,3,5]} >
      <GroupVoucher />
    </Protected >,
  },
  {
    path: "/create-voucher-skin",
    exact: false,
    main: () => <Protected permissions={[1,3,5]} >
      <SkinVoucher />
    </Protected >,
  },
  {
    path: "/receipt",
    exact: false,
    main: () => <IncomeFunction />,
  },
  {
    path: "/stores",
    exact: false,
    main: () => <Stores />,
  },
  {
    path: "/services",
    exact: false,
    main: () => <Services />,
    
  },
  {
    path: "/operators",
    exact: false,
    main: () => <Operators />,
  },
  // {
  //   path: "/booking",
  //   exact: true,
  //   main: () => <Booking />,
  // },
  {
    path: "/customer",
    exact: false,
    main: () => <Customer />,
  },
  {
    path: "/pos",
    exact: true,
    main: () => <Pos />,
  },
  {
    path: "/orders",
    exact: true,
    main: () => <Orders />,
  },
  {
    path: "/membercards",
    exact: true,
    main: () => <MemberCards />
  },
  {
    path: "/cards",
    exact: true,
    main: () => <Cards />
  },
  {
    path: "/report/daily",
    exact: false,
    main: () => <ReportDaily />,
  },
  {
    path: "/report/locations",
    exact: false,
    main: () => <ReportLocation />,
  },
  {
    path: "/report/customers",
    exact: false,
    main: () => <ReportCustomer />,
  },
  {
    path: "/report/cashiers",
    exact: false,
    main: () => <ReportCashier />,
  },
  {
    path: "/report/users",
    exact: false,
    main: () => <ReportUser />,
  },
  {
    path: "/report/products",
    exact: false,
    main: () => <ReportProduct />,
  },
  {
    path: "/vouchers",
    exact: false,
    main: () => <Protected permissions={[1]} >
      <Voucher />
    </Protected >,
  },
  {
    path: "/voucher/:id",
    exact: false,
    main: () => <Protected permissions={[1]} >
      <UpdateVoucher />
    </Protected >,
  },
  // {
  //   path: "/pre-booking",
  //   exact: false,
  //   main: () => <PreBooking />,
  // },
  // {
  //   path: "/pre-order",
  //   exact: false,
  //   main: () => <PreOrder />,
  // },
  // {
  //   path: "/change-password",
  //   exact: false,
  //   main: () => <ChangePassword />,
  // },
  {
    path: "/report/sales",
    exact: false,
    main: () => <ReportSales />,
  },
  {
    path: "/product",
    exact: false,
    main: () => <Product />,
  },
  {
    path: "/strapi",
    exact: false,
    main: () => <Strapi />,
  },
  {
    path: "/report-by-day",
    exact: false,
    main: () => <ReportByDay />,
  },
  // {
  //   path: "/report-by-ktv",
  //   exact: false,
  //   main: () => <ReportByKTV />,
  // },
  {
    path: "/report/promotion",
    exact: false,
    main: () => <PromotionLocation />,
  },

  {
    path: "/report/detail-promotion",
    exact: false,
    main: () => <DetailPromotion />,
  },

  {
    path: "/report/qr-code",
    exact: false,
    main: () => <DetailQrCode />,
  },
  {
    path: "/report/online",
    exact: false,
    main: () => <ReportTvOnline />,
  },

  {
    path: "/report/offline-lt",
    exact: false,
    main: () => <ReportLtOnline />,
  },
  {
    path: "/report/offline-ktv-tvv",
    exact: false,
    main: () => <ReportKtvTvvOffline />,
  },
  {
    path: "/report/service-detail",
    exact: false,
    main: () => <ReportServiceDetail />,
  },

  {
    path: "/data/sale-online",
    exact: false,
    main: () => <ListDataSaleOnline />,
  },
  {
    path: "/report/operate-booking",
    exact: false,
    main: () => <ReportOperateBooking />,
  },
  {
    path: "/report/business",
    exact: false,
    main: () => <ReportBusiness />,
  },
  {
    path: "/report/ktv-tv-details",
    exact: false,
    main: () => <ReportKtvTvDetails />,
  },
  {
    path: "/report/ktv-tvv-detail",
    exact: false,
    main: () => <ReportKtvTvvDetail />,
  },
  {
    path: "/report/total-price-booking",
    exact: false,
    main: () => <ReportStoreBooingPrice />,
  },

  {
    path: "/report/detail/sale-ctm",
    exact: false,
    main: () => <ReportSaleTvCtm />,
  },

  {
    path: "/emulation",
    exact: false,
    main: () => <Protected permissions={[1]} >
      <Emulation />
    </Protected >,
  },

  {
    path: "/report/emulation",
    exact: false,
    main: () => <ReportEmulation />,
  },

  {
    path: "/report/hopper",
    exact: false,
    main: () => <ReportCustomerBusiness />,
  },
];

export default routes;
