import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"

import { format, startOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "../scss/report.scss"
import { Table, Spin, Button } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../../utils/storage.service";
import Loading from "../../customer/Loading";

const ReportKtvTvvDetailCat = (props) => {
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [showloading, setShowloading] = useState(false)
    const [pageSize, setPageSize] = useState(10)

    // useEffect(() => {
    //     setTiltle("Theo nhân viên đặt lịch")
    // }, [])

 
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const columns = [
        { dataIndex: 'users_name', title: 'Nhân viên', width: 150  },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            dataIndex: 'total_price',
            title: 'Doanh số Thuần',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
        },
        {
            dataIndex: 'total_paid',
            title: 'Thực thu',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) =>Number(a.total_paid) - Number(b.total_paid),
                multiple: 1,
            },
        },
        {
            dataIndex: 'count_order_tv',
            title: 'HD tư vấn',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_service',
            title: 'Tổng Dv',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        
        {
            dataIndex: 'aov',
            title: 'AOV',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT Triệt',
            align: "start",
            // width: 150 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidTriet)}</p>
                        <p className="text2">Đơn KTV: {data.countTriet}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovTriet)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countDa',
            title: 'TT Da',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidDa)}</p>
                        <p className="text2">Đơn KTV: {data.countDa}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovDa)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT CS Body',
            align: "start",
            // width: 150 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidBody)}</p>
                        <p className="text2">Đơn KTV: {data.countBody}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovBody)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countKhac',
            title: 'TT Khác',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidKhac)}</p>
                        <p className="text2">Đơn KTV: {data.countKhac}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovKhac)}</p>
                    </div>
                )
            },
        },
    ];

    const handelExportUser = () => {
        // if (_state.loadingShowClickItem) return
        setShowloading(true)
      
        var url = `api/mysql/export/detail-user?type=1&user_id=${props.userId}&user_name=${props.userName}&start=${props.startDate}&end=${props.endDate}`
        axiosService(url, "GET")
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href = res.data.data.url
                setShowloading(false)
            }
        }).catch((error) => {
            console.log(error)
            setShowloading(false)
        });
    }
    const  handleChange = (e) => {
        setPageSize(e.pageSize)
    }

  
    return (
        <>
            <div style={{ backgroundColor: "white" }} className="p-2">
                
                <div className="border-bottom w-100 pb-2">
                    <Row>
                        <Col xs={6}>
                            <div>
                                Bảng số liệu chi tiết
                            </div>
                        </Col>
                        <Col xs={6}>
                            <Button size="middle" type="primary"
                             onClick={handelExportUser}
                            ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                            </Button>
                        </Col>
                    </Row>
                </div>
                    
                <Table 
                    columns={columns} 
                    dataSource={props.table}
                    pagination={{ pageSize: pageSize, showSizeChanger: true, pageSizeOptions: ['10', '15', '30', '50', '100'] }}
                    scroll={{ x: 600 }}
                    onChange={handleChange}
                    bordered
                />
            </div>
            <Loading
                show={showloading}
            ></Loading>
        </>
    )
}
export default ReportKtvTvvDetailCat