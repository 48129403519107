// import 'antd/dist/antd.min.css'
import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Search from "./Search"
import Header from "../Header";

import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth, endOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line } from '@ant-design/plots';
import { Table, Spin } from "antd"
import getDates from "./getDates"
import { DataGrid } from '@mui/x-data-grid';
import "./scss/report.scss"
import StorageService from "../../utils/storage.service";

const ReportCustomerBusiness = () => {
    const columns = [
        {
            title: 'Tên KH',
            dataIndex: 'customer_name',
            // fixed: 'left',
            render: (data) => {
                return (
                    <p className="text1">{data}</p>
                )
            },
        },
        {
            title: 'Sdt',
            dataIndex: 'mobile',
            // fixed: 'left',
            with: 150,
            render: (data) => {
                return (
                    <p className="text_service_none">{data}</p>
                )
            },
        },
        {
            title: 'Dịch vụ phễu',
            dataIndex: 'service',
            // align: "center",
            render: (data) => {
                let string = ''
                for(let item of data) {
                    string += `<p className="text1">${item}</p>`
                }
                return (<div dangerouslySetInnerHTML={{__html: string}} />)
            
            },
        },
        {
            title: 'Tiền mua phễu',
            dataIndex: 'total',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text7">{convertCurrency(data)}</p>
                )
            },
        },
        {
            title: 'Số đơn mua thêm',
            dataIndex: 'count_order',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text4">{data}</p>
                )
            },
        },
        {
            title: 'Doanh số mua thêm',
            dataIndex: 'total_more',
            // align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
            sorter: {
                compare: (a, b) => Number(a.total_more) - Number(b.total_more),
                multiple: 1,
            },
        },
       

    ];
    const [title, setTiltle] = useState("Báo cáo Dịch vụ phễu")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [total, setTotal] = useState(0)
    const [count, setCount] = useState(0)
    const [row, setRow] = useState(15)
    const [totalUpsale, setTotalUpsale] = useState(0) 
    const [countUpsale, setCountUpsale] = useState(0)
    const [totalNangCap, setTotalNangCap] = useState(0)
    const [countNangCap, setCountNangCap] = useState(0)

    const [userId, setUserId] = useState(0)
    const [emulationId, setEmulationId] = useState(0)

    const [userName, setUserName] = useState(null)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [emulationOption, setEmulationOption] = useState([])


    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }

    const hanldeUser = (x) => {
        setUserId(x.value)
        setUserName(x.label)
    }

    const hanldeEmulation = (x) => {
        setEmulationId(x.value)
    }

    function convertDateTime(event) {
        let date = new Date(event),
            month = date.getMonth() + 1,
            day = date.getDate();
            // hours = date.getHours(),
            // minus = date.getMinutes();
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        // if (hours < 10) hours = '0' + hours;
        // if (minus < 10) minus = '0' + minus;
        let text = date.getFullYear() + '-' + month + '-' + day ;
        // text = text + ' ' + hours + ':' + minus;
        return text
    };
    const fetchData = async (sD, eD, s) => {
        const { emulation, data } = await async.parallel({
            emulation: (cb) => {
                axiosService(`api/emulation/`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/reports/store/customer/business?start=${sD}&end=${eD}&storeId=${store}&user_id=${userId}&pIds=${emulationId}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {total, countMore, totalMore, countUpsale,totalNangCap, countNangCap, dataTable, option } = data.data

            // setChart(dataChart)
            setTable(dataTable)

            setTotal(total)
            setCount(countMore)
            setTotalUpsale(totalMore)
           
            setEmulationOption([...option])

        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store,keyword, userId, emulationId])

    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    const  handleChange = (e) => {
        setRow(e.pageSize)
        console.log(e)
    }

    return (
        <>
        <div className={`p-2 ${styles.background}`}>
            <Search startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                hanldeUser={hanldeUser}
                hanldeEmulation={hanldeEmulation}
                title={title} type={"hopper"}
                csvData={table} isExport={isExport}
                hanldeExportData={hanldeExportData}
                onChangeKeyword={onChangeKeyword}
                emulation = {emulationOption}
                header={
                    [
                        { label: 'Nhân viên', key: "user_name" },
                        { label: "Số lịch đã xác nhận", key: "countBooking" },
                        { label: "Hoa hồng xác nhận lịch", key: "rose_confirm" },
                        { label: "Số khách đón tiếp", key: `countCustomer` },
                        { label: "Hoa hồng đón tiếp", key: `rose_welcome` },
                    ]
                }
                userAdmin={userAdmin}
            />
            <div className="container-fluid">
                <Spin spinning={loading} tip="Đang tải...">
                    <Row className="p-2">
                        <Col md={8} xs={12}>
                            <Row className="h-100">
                                <Col md={4} xs={12}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Đơn mua dịch vụ phễu: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Tổng tiền: </span>
                                                <span className={styles.textTag4}> Số khách hàng: </span>
                                            </div>
                                            <div className="ml-3">
                                                {/* <span className={styles.textTag}>
                                                    {convertCurrency(totalPaid)}</span> */}
                                                <br />
                                                <span className={styles.textTag2}> {convertCurrency(total)}</span>
                                                <span className={styles.textTag2}> {table.length}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col md={4} xs={12}>
                                    <Tag color="green" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Đơn mua thêm: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Tổng tiền: </span>
                                                <span className={styles.textTag4}> Số đơn: </span>
                                            </div>
                                            <div className="ml-3">
                                                {/* <span className={styles.textTag}>
                                                    {convertCurrency(totalPaid)}</span> */}
                                                <br />
                                                <span className={styles.textTag2}> {convertCurrency(totalUpsale)}</span>
                                                <span className={styles.textTag2}> {count}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                {/* <Col md={4} xs={12}>
                                    <Tag color="violet" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Đơn nâng cấp: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Tổng tiền: </span>
                                                <span className={styles.textTag4}> Số đơn: </span>
                                            </div>
                                            <div className="ml-3">
                                            
                                                <br />
                                                <span className={styles.textTag2}> {convertCurrency(totalNangCap)}</span>
                                                <span className={styles.textTag2}> {countNangCap}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col> */}

                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-2" style={{alignItems: 'end'}}>
                        {/* <Col xs={12} md={5} className="hide-mobile">
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <Line {...config} />
                            </div>
                        </Col> */}
                        <Col xs={12} md={12}>
                            <div style={{ backgroundColor: "white" }} >
                                <div className="border-bottom mb-1 p-2">
                                    <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                                </div>
                                <div style={{ height: 495, width: '100%' }} className="p-2">
                                    <Table 
                                        columns={columns} 
                                        dataSource={[...table]}
                                        pagination={{ pageSize: row, showSizeChanger: true, pageSizeOptions: ['5', '15', '30', '50', '100'] }}
                                        scroll={{ x: 900 }}
                                        onChange={handleChange}
                                        bordered
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
        </>
    )
}
export default ReportCustomerBusiness