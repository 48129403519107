import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "../Header"
import styles from "../style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth, endOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line, Column } from '@ant-design/plots';
import { Table, Spin, Tabs } from "antd"
import getDates from "../getDates"
import { DataGrid } from '@mui/x-data-grid';
import "../scss/report.scss"
import StorageService from "../../../utils/storage.service";
import ReportKtvTvvDetailCat from "./ReportKtvTvvDetailCat";
import ReportKtvTvvDetailDate from "./ReportKtvTvvDetailDate";
import ReportKtvTvvTargetDetail from "./ReportKtvTvvTargetDetail"
const { TabPane } = Tabs;

const ReportKtvTvvDetail = () => {
    const columns = [
        { dataIndex: 'users_name', title: 'Nhân viên', width: 150  },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            dataIndex: 'total_price',
            title: 'Doanh số Thuần',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
        },
        {
            dataIndex: 'total_paid',
            title: 'Thực thu',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
        },
        {
            dataIndex: 'count_order_tv',
            title: 'HD tư vấn',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_service',
            title: 'Tổng Dv',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        
        {
            dataIndex: 'aov',
            title: 'AOV',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT Triệt',
            align: "start",
            // width: 150 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidTriet)}</p>
                        <p className="text2">Đơn KTV: {data.countTriet}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovTriet)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countDa',
            title: 'TT Da',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidDa)}</p>
                        <p className="text2">Đơn KTV: {data.countDa}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovDa)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT CS Body',
            align: "start",
            // width: 150 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidBody)}</p>
                        <p className="text2">Đơn KTV: {data.countBody}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovBody)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countKhac',
            title: 'TT Khác',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidKhac)}</p>
                        <p className="text2">Đơn KTV: {data.countKhac}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovKhac)}</p>
                    </div>
                )
            },
        },
    ];
    var date = new Date();
    // date.setDate(date.getDate() - 1);
    let month = date.getMonth()
    let year = date.getFullYear()
    if (date.getMonth() == 0) {
        month = 12
        year = year - 1
    }
    let oldMonth = year + '-' + month + '-'
    let day = date.getDate()
    let endOfMonth1 = new Date(endOfMonth(new Date(date)))
    let endOfMonthOld = new Date(endOfMonth(new Date(oldMonth + day)))
    if (day == endOfMonth1.getDate()) {
        day = endOfMonthOld.getDate()
    }

    let olDate = new Date(oldMonth + day)
    // console.log(format(new Date(startOfMonth(date)),"yyyy-MM-dd"))
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(date)),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(date), 'yyyy-MM-dd'))
    const [startOldDate, setStartOldDate] = useState(format(new Date(startOfMonth(olDate)),"yyyy-MM-dd"))
    const [endOldDate, setEndOldDate] = useState(format(new Date(olDate), 'yyyy-MM-dd'))

    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [sortBy, setSortBy] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalOrderNew, setTotalOrderNew] = useState(0)
    const [totalOrderTriet, setTotalOrderTriet] = useState(0)
    const [totalOrderDa, setTotalOrderDa] = useState(0)
    const [totalPaidTriet, setTotalPaidTriet] = useState(0)
    const [totalPaidDa, setTotalPaidDa] = useState(0)
    const [totalPaidKhac, setTotalPaidKhac] = useState(0)
    const [ordersByDaysCountBooking, setOrdersByDaysCountBooking] = useState([])
    const [totalKhac, setTotalKhac] =   useState('0%')
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [totalOrderBody, setTotalOrderBody] = useState(0)
    const [totalPaidBody, setTotalPaidBody] = useState(0)
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [pageSize, setPageSize] = useState(5)
    const [key, setKey] =  useState([])
    const [userId, setUserId] = useState(userAdmin.id)
    const [userName, setUserName] = useState(userAdmin.name)
    const [target, steTarget] = useState([])
    const [targetDetail, settargetDetail] = useState([])
    const [totalPaidTarget, setTotalPaidTarget] = useState(
        {
            totalPaidUpPremium: 0,
            totalPaidPremium08: 0, 
            totalPaidPremiumFullbody: 0,
            totalPaidDauTriet: 0,
            totalPaidCentUpgrade: 0,
            totalPaidTheoVung: 0,
            totalPaidBody: 0,
            totalPaidDa: 0
        })
    const hanldeRangeDate = useCallback((sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
        handleOleDate(sDate, eDate)
    }, [startDate, endDate])

    const hanldeStore = useCallback((e) => {
        setStore(e)
    }, [store])
    
    const hanldeRangeDateOld = useCallback((sDate, eDate) => {
        setStartOldDate(sDate)
        setEndOldDate(eDate)
       
    }, [startOldDate, endOldDate])

    const handleOleDate = async (start, end) => {
        start = new Date(start)
        end = new Date(end)
        let oldMonth = start.getFullYear() + '-' + start.getMonth() + '-'
        let olDate = format(new Date(startOfMonth(new Date(oldMonth + start.getDate()))), 'yyyy-MM-dd' )
        let olEndDate = format(new Date(new Date(oldMonth + end.getDate())), 'yyyy-MM-dd' )
        setStartOldDate(olDate)
        setEndOldDate(olEndDate)
    }

    const hanldeSortBy = useCallback((e) => {
        setSortBy(e)
    }, [sortBy]);

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate, sortBy, startOldDate, endOldDate)
    }, [store, endDate, startDate, sortBy, startOldDate, endOldDate])
    
    const fetchData = async (s, eD, sD, sBy, startOldDate, endOldDate) => {
        const rs = await axiosService(`api/reports/store/work/ktv-details/${s}?storeId=${s}&startDate=${sD}&endDate=${eD}&startOldDate=${startOldDate}&endOldDate=${endOldDate}&sortBy=${sBy}`, "GET")
        const { data } = rs.data
        if (rs.status == 200) {
            setTotalPaid(data.totalPaid)
            setTotalOrderNew(data.totalOrderNew)
            setTotalOrderTriet(data.totalOrderTriet)
            setTotalOrderDa(data.totalOrderDa)
            setTotalKhac(data.totalKhac)
            setTotalPaidTriet(data.totalPaidTriet)
            setTotalPaidDa(data.totalPaidDa)
            setTotalPaidKhac(data.totalPaidKhac)
            setRows(data.dataTable)
            setTotalOrderBody(data.totalOrderBody)
            setTotalPaidBody(data.totalPaidBody)
            setKey(data.key)
            steTarget(data.target)
            settargetDetail(data.targetDetail)
            setTotalPaidTarget(data.totalTagetOf)
            console.log(data.totalTagetOf)
            let rowsCountBooking = []
            let rowsCountBookingSuccess = []
            let rowsCountBookingService = {}
            let rowsCountService = []
            for (let item of data.dataTable) {
                let rowCountBooking = {
                    date: item.users_name,
                    value: Number(item.comparePaid),
                    type: 'Tiền thực thu'
                }
                let rowCountBookingSuccess = {
                    date: item.users_name,
                    value: Number(item.compareOrderTv),
                    type: 'Đơn hàng mới'
                }
                if (rowsCountBookingService[item.users_name] == undefined) {
                    rowsCountBookingService[item.users_name] = {
                        date: item.users_name,
                        value: Number(item.compareAov),
                        type: 'Aov'
                    }
                } else {
                    rowsCountBookingService[item.users_name]['value'] +=  Number(item.compareAov)
                    rowsCountBookingService[item.users_name]['value'] = Number(Number(rowsCountBookingService[item.users_name]['value']).toFixed(1))
                }
                let rowCountService = {
                    date: item.users_name,
                    value: Number(item.compareService),
                    type: 'Dịch vụ phục vụ'
                }
                rowsCountBooking.push(rowCountBooking)
                rowsCountBookingSuccess.push(rowCountBookingSuccess)
                rowsCountService.push(rowCountService)
            }
            let rowsCountBookingServiceArr = []
            for (let item of Object.values(rowsCountBookingService)) {
                rowsCountBookingServiceArr.push(item)
            }
            setOrdersByDaysCountBooking([...rowsCountBooking, ...rowsCountBookingSuccess, ...rowsCountBookingServiceArr])
            let value = "yyyy-MM-dd"
                if (sortBy === 1) {
                    value = "ww"
                } else if (sortBy === 2) {
                    value = "MM"
                }
            setLoading(false)
        }
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const config = useMemo(() => configChart(ordersByDaysCountBooking, convertCurrency), [ordersByDaysCountBooking])
    
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const  handleChange = (e) => {
        setPageSize(e.pageSize)
    }

    return (
        <div className={`${styles.background} report-page`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                sortBy={sortBy} hanldeSortBy={hanldeSortBy}
                title={"Bảng chi tiết từng KTV"} type={"ktv-tvv-detail"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thời gian', key: "date" },
                        { label: "Số lượng lịch đặt", key: "count_booking" },
                        { label: "Số lượng lịch huỷ", key: "count_close" },
                        { label: "Số lượng lịch thành công", key: "count_success" },
                        { label: "Tỉ lệ huỷ/tổng lịch đặt", key: "proportion" },
                        { label: "Số lượng khách hàng phục vụ", key: "count_customer" },
                        { label: "Lượt dịch vụ", key: "count_service" },
                    ]
                }
                userAdmin={userAdmin}
                startOldDate={startOldDate} endOldDate={endOldDate}
                hanldeRangeDateOld={hanldeRangeDateOld}
                
            />
            <div className="container-fluid">
                <Spin spinning={loading}>
                    <Row className="mt-2">
                        <Col xs={12} md={8}>
                            <Row className="h-100">
                                <Col xs={6} md={3} className="mb-2">
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Tổng tiền thực thu: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Số hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaid)}</span>
                                                <span className={styles.textTag2}> {totalOrderNew}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={12} md={3} className="mb-2">
                                        <Tag color="green" className=" w-100 h-100 p-2">
                                            <p className={styles.textTag3}>THỰC THU NHÓM DỊCH VỤ</p>
                                            <div className="d-flex justify-content-between p-2">
                                                <div>
                                                    <p  className={styles.textTag4}>Thực thu Triệt:</p> 
                                                    <p  className={styles.textTag4}>Thực thu Da: </p> 
                                                    <p  className={styles.textTag4}>Thực thu Body:</p>
                                                    <p  className={styles.textTag4}>Thực thu dịch vụ Khác:</p>


                                                </div>
                                                {/*  */}
                                                <div>
                                                    <p  className={styles.textTag3}>{convertCurrency(totalPaidTriet)}</p>
                                                    <p  className={styles.textTag3}>{convertCurrency(totalPaidDa)}</p>
                                                    <p  className={styles.textTag3}>{convertCurrency(totalPaidBody)}</p>
                                                    <p  className={styles.textTag3}>{convertCurrency(totalPaidKhac)}</p>
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                <Col xs={12} md={6}>
                                    <Row className="mh-172">
                                        <Col xs={12} md={6} className="mb-2">
                                            <Tag className=" w-100 h-100 p-2" style={{background: '#fb8a25'}}>
                                                <p className={styles.textTag3}>KẾT QUẢ MỤC TIÊU</p>
                                                <div className="d-flex justify-content-between p-2">
                                                    <div>
                                                        <p  className={styles.textTag4}>Nâng cấp Triệt lông Premium</p> 
                                                        <p  className={styles.textTag4}>Triệt lông Premium 08 vùng</p> 
                                                        <p  className={styles.textTag4}>Triệt lông Premium Fullbody</p>
                                                        <p  className={styles.textTag4}>Đầu triệt cá nhân</p>


                                                    </div>
                                                    {/*  */}
                                                    <div>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidUpPremium)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidPremium08)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidPremiumFullbody)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidDauTriet)}</p>
                                                    </div>
                                                </div>
                                            </Tag>
                                        </Col>
                                        <Col xs={12} md={6} className="mb-2">
                                            <Tag className=" w-100 h-100 p-2" style={{background: '#fb8a25'}}>
                                                <p className={styles.textTag3}>KẾT QUẢ MỤC TIÊU</p>
                                                <div className="d-flex justify-content-between p-2">
                                                    <div>
                                                        <p  className={styles.textTag4}>Cent Upgrade</p> 
                                                        <p  className={styles.textTag4}>Triệt lông theo vùng</p> 
                                                        <p  className={styles.textTag4}>Chăm sóc Body</p>
                                                        <p  className={styles.textTag4}>Chăm sóc Da</p>


                                                    </div>
                                                    {/*  */}
                                                    <div>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidCentUpgrade)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidTheoVung)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidBody)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidDa)}</p>
                                                    </div>
                                                </div>
                                            </Tag>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Triệt: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Số hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidTriet)}</span>
                                                <span className={styles.textTag2}> {totalOrderTriet}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Da: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Số hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidDa)}</span>
                                                <span className={styles.textTag2}> {totalOrderDa}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu Body: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Số hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidBody)}</span>
                                                <span className={styles.textTag2}> {totalOrderBody}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={2} className="pay_type">                   
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Thực thu dịch vụ Khác: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <span className={styles.textTag4}>Số hoá đơn:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaidKhac)}</span>
                                                <span className={styles.textTag2}> {totalKhac}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col> */}
                            
                            
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-2" style={{alignItems: 'end'}}>
                        <Col xs={12} md={12}>
                            <div style={{ backgroundColor: "white" }} >
                                {/* <div className="border-bottom mb-1 p-2">
                                    <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                                </div> */}
                                <div style={{ }} className="p-2">
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="Theo nhóm dịch vụ" key="1">
                                            <ReportKtvTvvDetailCat
                                                table = {rows}
                                                startDate={startDate}
                                                endDate={endDate}
                                                userId={userId}
                                                userName={userName}
                                            >
                                            </ReportKtvTvvDetailCat>
                                        </TabPane>
                                        <TabPane tab="Theo thời gian" key="2">
                                            <ReportKtvTvvDetailDate
                                                table = {target}
                                                startDate={startDate}
                                                endDate={endDate}
                                                userId={userId}
                                                userName={userName}
                                                keyTable={key}
                                            >
                                            </ReportKtvTvvDetailDate>
                                        </TabPane>
                                        <TabPane tab="Chi tiết KTV" key="3">
                                            <ReportKtvTvvTargetDetail
                                                table = {targetDetail}
                                                startDate={startDate}
                                                endDate={endDate}
                                                userId={userId}
                                                userName={userName}
                                                keyTable={key}
                                            >
                                            </ReportKtvTvvTargetDetail>
                                        </TabPane>
                                    </Tabs>
                                    {/* <Table 
                                        columns={columns} 
                                        dataSource={rows}
                                        pagination={{ pageSize: pageSize, showSizeChanger: true, pageSizeOptions: ['5', '15', '30', '50', '100'] }}
                                        scroll={{ x: 300 }}
                                        onChange={handleChange}
                                        bordered
                                    /> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2" style={{alignItems: 'end'}}>
                        <Col xs={12} md={12} className="hide-mobile">
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <Column {...config} />
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div >

    )
}
const configChart = (data, convertCurrency) => {  
    var config = {
        data: data,
        xField: "date",
        yField: "value",
        seriesField: "type",
        height: 700,
        onResize: true,
        isStack: true,
        colorField: 'type', // or seriesField in some cases
        xAxis: {
            title: {
              text: "KTV",
              position: "end",
              offset: 0,
              spacing: 8,
              style: {
                fontSize: 13,
                fontWeight: 900,
                textAlign: "start"
              }
            },
            line: {
              style: {
                stroke: "black"
              }
            },
            tickLine: {
              style: {
                stroke: "black"
              }
            },
            label: {
              autoRotate: true,
              style: {
                fill: "black",
              //   fontSize: 10
              },
            }
          },
        yAxis: {
            line: {
              style: {
                lineWidth: 2
              }
            },
            label: {
              style: {
                fill: "black"
              },
              offset: 15,
              formatter: (text) => _.round(_.divide(text, 1), 2).toLocaleString()
            }
        },
        color: ["#673ab7", "#2196f3", "#4caf50"],
        label: {
            position: 'middle', // 'top', 'bottom', 'middle'
            formatter: (text) => {
                return text.value + '%';
            },
            style: {
                fontWeight: 600
            }
          
        },
        interactions: [
            {
                type: 'active-region',
                enable: false
            }
        ],
        connectedArea: {
            style: (oldStyle) => {
                return {
                    fill: 'rgba(0,0,0,0.2)',
                    stroke: oldStyle.fill,
                    lineWidth: 1
                };
            }
        },
        legend: {
            position: "right",
            marker: {
              symbol: "square"
            },
            itemName: {
              formatter: (text, item, index) => {
                return '';
              }
            }
        },
        tooltip: {
            customContent: (count, data) => {
                let fileHtml = ''
                let title = ''
                for(let item of data) {
                    title = item.title
                    let name = item.name
                    let value = Number(item.value)
                    fileHtml += '<li class="g2-tooltip-list-item">'
                    fileHtml += '<span class="g2-tooltip-marker" style="background: '+item.color+'; width: 8px; height: 8px; border-radius: 50%; display: inline-block; margin-right: 8px;">' + '</span>'
                    fileHtml += '<span class="g2-tooltip-name" style="color: '+item.color+';">'+item.name + '</span>'
                    fileHtml += '<span class="g2-tooltip-value" style="color: '+item.color+';">'+ value + ' %</span>'
                    fileHtml += '</li>'
                }
                return `<div class="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px; padding-top: 12px; font-weight: bold; font-size: 15px"><p> ${title} </div>
                    <ul class="g2-tooltip-list" style="margin: 0px; list-style-type: none; padding: 12px !important; ">
                        ${fileHtml}
                        </br>
                    </ul>
                </div>`;
            }
        },
      };
    return config
}
export default ReportKtvTvvDetail

// formatter: (text) => {
//     const oldLabel = text;
//     const labelLength = oldLabel.replace(/[^x00-xff]/g, "xx").length;
//     let newLabel = "";
//     if (labelLength > 12) {
//       let strLen = 0;
//       let firstStr = "";
//       let lastStr = "";
//       for (let i = 0; i < labelLength; i++) {
//         if (oldLabel.charCodeAt(i) > 128) {
//           strLen += 2;
//         } else {
//           strLen++;
//         }
//         if (strLen <= 12) {
//           firstStr += oldLabel.charAt(i);
//         } else {
//           lastStr += oldLabel.charAt(i);
//         }
//       }
//       newLabel = `${firstStr}\n${lastStr}`;
//     } else {
//       newLabel = oldLabel;
//     }
//     return newLabel;
//   }