import React, { useState, useEffect } from "react";
import { testVoucher } from "./constant/PosCustomer";
import CurrencyInput from "react-currency-input-field";
import * as convert from "../constant/Convert.helper";
import axiosService from "../../utils/axios.service";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons"
import { message, Button, Spin } from "antd"
import VoucherItem from './VoucherItem';

import Select from "react-select";
import _ from "lodash"

var currentStore = localStorage.getItem("currentStore")
const PosPopupVoucherList = (props) => {
  const [optionValue, setOptionValue] = useState([])
  const [searchString, setSearchString] = useState('')
  const [listSelect, setListSelect] = useState([...props.listVoucher])
  const [statusButton, setStatusButton] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect( async () => {
    let data = {
      "customer": props.customer,
      "products": props.products,
      "store": currentStore,
      "total": props.total,
      "booking_voucher_id": props.currentPos.booking_voucher_id,
      "tabSelect": props.tabSelect,
      "type_order": props.currentPos.type
    }
  const res = await axiosService("api/vouchers/take/voucher/" + props.customer.id, "POST", data)
  if(res.data.success) {
    setLoading(false)
    setOptionValue(res.data.data)
  }

  }, [props.customer])

  const handleSelectVoucher = (e) => {
    let data = [...props.listVoucher]
    let check = true
    if (data.length > 0) {
      for (let val of data) { 
        if (e.code == val.code || e.level == val.level) {
          check = false
        }
      }
    }
    if (!check) {
      let dataVoucher = props.listVoucher
      props.handleClearVoucher(e.id)
      _.remove(dataVoucher, function(n) { return n.id == e.id })
      return
    }
    props.selectVoucher(e)
    return 
  }

  const searchVoucher = (e) => {
    const value = e.target.value
    // let dataNew = []
    // dataNew = _.filter(optionValue, function(o) { 
    //   if (_.includes(o.name,value) || _.includes(o.code,value) ) {
    //     return true
    //   }
    // })
    setSearchString(value)
    // setOptionValue([...dataNew])

  }

  const checkSelectVoucher = (item) => {
    let classAc = "grid-two-column"
    let data = [...props.listVoucher]
    if (data.length == 0) {
      classAc = "grid-two-column"
    }
    for (let val of data) { 
        if(item.code == val.code) {
          classAc = "grid-two-column active"
        }
        if (item.level == val.level && item.code != val.code) {
          classAc = "grid-two-column disable-package2"
        }
    }
    return classAc
  }

  const funcSaveSelect =(e) => {
    props.handleShowList()
  }

  const onSubmitVoucherPopup = async () => {
    props.setStatusButton(false)
    var voucher = searchString
    let select = props.listVoucher
    let tickVoucher = _.find(optionValue.allLevel, function(o) { return o.code === voucher; });

    if (tickVoucher == undefined) {
      props.setStatusButton(true)
      return message.error("Không tìm thấy mã voucher này")
    }

    let check = false
    if (select.length > 0) {
      let filterVoucher = _.filter(select, function(o) { return o.code === tickVoucher.code; });
      if (filterVoucher.length > 0) {
        props.setStatusButton(true)
        return message.success("Voucher đã được thêm trước đó")
      } else {
        let filterLevel = _.filter(select, function(o) { return o.level === tickVoucher.level; });
        if (filterLevel.length > 0) {
          props.setStatusButton(true)
          return message.error(`Đã có voucher "${filterLevel[0].code}" cùng level`)
        } else {
          check = true
        }
      }
    } else {
      check = true
    }

    if (tickVoucher.code == undefined) check = true
    if (check) {
      props.onSubmitVoucher(voucher)
    }
  }
 
  return (
    <div className="popup-cent voucher-list">
      <div className="content-popup fadeInDown">
        <div className="head-popup">
          <div className="title">Danh sách mã khách hàng được áp dụng</div>
          <div className="close" onClick={props.handleShowList}>×</div>
        </div>
        {props.products.includes(props.waxing_upgrade) ?
          <div style={{textAlign: "center", paddingTop: "50px"}}>Không áp dụng voucher cho đơn này</div>
        :
          <Spin spinning={loading} size="large" tip="Đang tải...Xin vui lòng đợi">
            <div className="body-popup">
              <div className="total-price payment-block">
                <div className="title-block left-block">Chọn mã voucher cần áp dụng </div>
                <div className="input-action-voucher">
                  <input id="search-product-input"
                    name="serviceSearchString"
                    value={searchString}
                    onChange={searchVoucher}
                    // onPressEnter={onSubmitVoucherPopup}
                    className="search-input customer-block-filter form-control input-xs ng-pristine ng-valid ng-empty ng-touched"
                    placeholder="Tìm kiếm theo tên hoặc mã thẻ" />
                  {props.statusButton ?
                    <Button className="ml-2" icon={<CheckOutlined style={{ color: "green" }} onClick={onSubmitVoucherPopup} />} />
                  :
                    <Button className="ml-2" icon={<LoadingOutlined style={{ color: "#4a90e2" }} />} />
                  }        
                </div>
                {optionValue.level1 && optionValue.level1.length > 0 ?
                  <div className="product-grid">
                      <div  className="title-list">Mã voucher level 1</div>
                      {optionValue.level1?.map((item, key) => (
                        searchString ?
                          _.includes(item.name,searchString) || _.includes(item.code,searchString) ?
                            <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                                <VoucherItem voucher={item} />
                                <div className="close">×</div>
                            </div>
                          : null
                        : 
                          <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                            <VoucherItem voucher={item} />
                            <div className="close">×</div>
                          </div>
                      ))}
                  </div>
                : null}
                {optionValue.level2 && optionValue.level2.length > 0 && [1,2,4].includes(props.currentPos.type) ?
                  <div className="product-grid">
                    <div className="title-list">Mã voucher level 2</div>
                    {optionValue.level2?.map((item, key) => (
                      searchString ?
                        _.includes(item.name,searchString) || _.includes(item.code,searchString) ?
                          <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                            <VoucherItem voucher={item} />
                            <div className="close">×</div>
                          </div>
                        : null
                      : 
                        <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                          <VoucherItem voucher={item} />
                          <div className="close">×</div>
                        </div>
                    ))}
                  </div>
                : null}
                {optionValue.level3 && optionValue.level3.length > 0 ?
                  <div className="product-grid">
                    <div className="title-list">Mã voucher level 3</div>
                      {optionValue.level3?.map((item, key) => (
                        searchString ?
                          _.includes(item.name,searchString) || _.includes(item.code,searchString) ?
                            <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                              <VoucherItem voucher={item} />
                              <div className="close">×</div>
                            </div>
                          : null
                        : 
                          <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                            <VoucherItem voucher={item} />
                            <div className="close">×</div>
                          </div>
                      ))}
                  </div>
                : null}
                {optionValue.voucherLevel3GiftCart && optionValue.voucherLevel3GiftCart.length > 0 ?
                  <div className="product-grid">
                    <div className="title-list">Quà tặng</div>
                    {optionValue.voucherLevel3GiftCart?.map((item, key) => (
                      searchString ?
                        _.includes(item.name,searchString) || _.includes(item.code,searchString) ?
                          <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                            <VoucherItem voucher={item} />
                            <div className="close">×</div>
                          </div>
                        : null
                      :
                        <div key={key} className={checkSelectVoucher(item)} onClick={(e) => handleSelectVoucher(item, 0)} role="button" tabIndex="0">
                          <VoucherItem voucher={item} />
                          <div className="close">×</div>
                        </div>
                    ))}
                  </div>
                : null}
              </div>
            </div>
            <div className="bottom-popup">
              <div className="button-container">
                  <button className="button-cent" onClick={(e) => funcSaveSelect(e)}>
                    <span>
                      <i className="fas fa-save"></i>
                    </span>
                    Hoàn thành
                  </button>
              </div>
            </div>
          </Spin>
        }
      </div>
      <div className="overlay" onClick={props.handleShowList}></div>
    </div>
  );
};

export default PosPopupVoucherList;
