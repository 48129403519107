import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useReactToPrint } from "react-to-print";
import { message, Button } from "antd"
import axiosService from "../../utils/axios.service";
import { ApplyEnum, IsForVoucher } from "../../enums/voucher.enum";
import PosPopupVoucherList from './PosPopupVoucherList';
import PopupSelectAvatarCustomer from './PopupSelectAvatarCustomer';
import moment from "moment"
import AvatarCustomer from './AvatarCustomer';
import { notifications } from "../constant/Notify";
import role from '../../enums/role.enum';

import _ from "lodash"
// import TemplatePrintOrder from './TemplatePrintOrder';
import * as convert from "../constant/Convert.helper";

var currentStore = localStorage.getItem("currentStore")

export const PosOrderTotal = (props) => {
  let _props = props;

  const [voucherPrice, setVoucherPrice] = useState(0)
  const [voucherProductPrice, setVoucherProductPrice] = useState(0)
  const [comboPrice, setComboPrice] = useState(0)
  const [statusButton, setStatusButton] = useState(true)
  const invoiceRef = useRef(null);
  const [showPrint, setShowPrint] = useState(true)
  const onBeforeGetContentResolve = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voucher, setVoucher] = useState("")
  const [isForVoucher, setIsForVoucher] = useState(0)
  const [listVoucher, setListVoucher] = useState([])
  const [showVoucherList, setVoucherList] = useState(false);
  const [showCustomerAvatarList, setShowCustomerAvatarList] = useState(0);
  const [productIds, setProductIds] = useState([]);
  const [discountLevel3, setDiscountLevel3] = useState(0)
  const [userAdmin, setUserAdmin] = useState(JSON.parse(localStorage.getItem("user_info")))
  const [listActiveHanet, setListActiveHanet] = useState(null)
  const [selectAvatar, setSelectAvatar] = useState(null)
  const [checkCallApi, setCheckCallApi] = useState(false)
  const [checkChangeOrder, setCheckChangeOrder] =  useState(Number)
  const [finalPrice, setFinalPrice] =  useState(0)
  const [totalPrice, setTotalPrice] =  useState({
    price: 0,
    totalItems: 0
  })
  const [ipadScreen, setIpadScreen] =  useState(0)
  const [ipadStatus, setScreenStatus] = useState(0);

  const checkPrice = () => {
    let totalPrice = 0,
        itemsService = 0,
        data = {
          price: 0,
          totalItems: 0
        };

    Object.keys(_props.itemSelected).map((key) => {
      itemsService += props.itemSelected[key].quantity;
      totalPrice += (parseFloat(props.itemSelected[key].price) * props.itemSelected[key].quantity) - props.itemSelected[key].discount;
      return true;
    });

    data = {
      ...data,
      price: totalPrice,
      totalItems: itemsService
    }

    var price = totalPrice - comboPrice - voucherPrice - props.currentPos?.paid_amount_before;
    if (props.tabSelect == 4) {
      price = totalPrice - comboPrice - props.currentPos?.paid_amount_before;
    }
    
    setFinalPrice(price >= 0 ? price : 0)
    setTotalPrice(data)
  }

  const handleOnBeforeGetContent = useCallback(() => {
    setShowPrint(false)
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      resolve();
    });
  });

  const handleAfterPrint = useCallback(() => {
    setShowPrint(true)
  }, [setShowPrint]);

  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    onBeforeGetContent: handleOnBeforeGetContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });

  const handLeOpenVoucher = () => {
    if (isForVoucher === IsForVoucher.NO) {
      message.warning("Combo không đi kèm với voucher")
    } else {
      setIsModalOpen(!isModalOpen)
      if (isModalOpen) {
        setVoucher("")
      }
    }
  }
  
  const handleChangeTextVoucher = (e) => {
    setVoucher(e)
  }

  const onSubmitVoucher = async (voucher) => {
    try {
      const filtersProduct = props.itemSelected.filter(x => {
        return x.price > 0 && x.product_id != null
      })
      const mapProducts = filtersProduct.map(x => {
        return x.product_id
      })
      const body = {
        // orderAt: props.orderSelected.created_at,
        total: totalPrice.price,
        products: [...new Set(mapProducts)],
        store: localStorage.getItem("currentStore"),
        customer: props.currentPos.customers,
        type_order: props.currentPos.type ?? 0,
        booking_voucher_id: props.currentPos.booking_voucher_id,
        tabSelect: props.tabSelect
      }
      const res = await axiosService("api/vouchers/check/" + voucher, "POST", body)
      const { data } = res
      if (data.code === 404) {
        message.warning(data.message)
      } else if (data.code === 200) {
        let dataVoucher = listVoucher;
        dataVoucher.push(data.data)

        let idVoucher = _.map(dataVoucher, 'id');

        setListVoucher(dataVoucher)
        let dataPrice = handlePriceVoucher()
        setIsModalOpen(false)
        props.setLimitPaidOrder(data.data.limit_paid_order)
        if (props.tabSelect == 4) {
          dataPrice = 0
        }

        props.updateDiscountByRule({id: idVoucher.join(","), totalAmount: dataPrice , vcProductPriceItem:  voucherProductPrice })
        message.success(data.data.message)
      } else {
        message.error("Có lỗi xảy ra")
      }
      setStatusButton(true)
    } catch (error) {
      console.error(error)
      setStatusButton(true)
      message.error("Có lỗi khi thêm voucher")
    }
  }

  const onSubmitSelectVoucher = async (select) => {
    try {
      if (select.apply != 5 && select.apply != 4) {
        handleDefaultVoucher(select)
      } else {
        handleVoucherPrice(select)
      }
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }

  const handleVoucherPrice = (select) => {
    let dataPrice = voucherPrice;
    let vcProductPriceItem = voucherProductPrice
    let dataVoucher = listVoucher;
    dataVoucher.push(select)
    // let productSelected = {...props.}
    let totalDiscount = 0
    props.itemSelected.map((val, key) => {
        if (select.packages.includes(String(val.product_id))) {
          let discount = 0
          if (select.apply == 5) {
            discount = Number(select.amount)
          } 
          if (select.apply == 4) {
            discount = (val.price*Number(select.amount/100)).toFixed()
          }
          totalDiscount += discount
          props.itemSelected[key].discount += discount
        }
    })

    let idVoucher = _.map(dataVoucher, 'id');

    vcProductPriceItem += totalDiscount
    setVoucherProductPrice(vcProductPriceItem)
    setListVoucher(listVoucher)
    setIsModalOpen(false)
    // console.log(dataPrice, vcProductPriceItem, 1)
    if (props.tabSelect == 4) {
      dataPrice = 0
    }
    // console.log(voucherProductPrice)
    setVoucherPrice(dataPrice)
    props.updateDiscountByRule({ amount: 0, id: idVoucher.join(","), totalAmount: dataPrice, listVoucher: listVoucher, vcProductPriceItem: vcProductPriceItem })
    message.success(select.message)

  }

  const handleDefaultVoucher = (select) => {
    let dataVoucher = listVoucher;
    dataVoucher.push(select)

    let idVoucher = _.map(dataVoucher, 'id');
    setListVoucher(dataVoucher)
    let dataPrice = handlePriceVoucher()
    setIsModalOpen(false)
    props.setLimitPaidOrder(select.limit_paid_order)
    // console.log(dataPrice, voucherProductPrice, 2)
    props.updateDiscountByRule({id: idVoucher.join(","), totalAmount: dataPrice, listVoucher: listVoucher, vcProductPriceItem: voucherProductPrice })
    message.success(select.message)
  }

  const handlePriceVoucher = () => {
    let dataPrice = voucherPrice;
    let priceVoucherNotPercent = 0
    let priceVoucherIsPercent = 0

    let listSortBy = _.sortBy(listVoucher, [function(o) { return o.level; }]);

    for (let item of listSortBy) {
      if (item.apply != ApplyEnum.PERCENT) {
        priceVoucherNotPercent += item.amount
      }
    }

    for (let item of listSortBy) {
      if (item.apply == ApplyEnum.PERCENT) {
        let price = (totalPrice.price - comboPrice - priceVoucherNotPercent - priceVoucherIsPercent) * (item.amount / 100) || 0
        priceVoucherIsPercent += (price > item.maxAmount) ? item.maxAmount : price
      }
    }

    dataPrice = priceVoucherIsPercent + priceVoucherNotPercent
    setDiscountLevel3(priceVoucherIsPercent)
    setVoucherPrice(dataPrice)
    return dataPrice
  }

  const handleClearVoucher = (key) => {
    try {
      let dataVoucher = listVoucher
      let dataPrice = voucherPrice
      let voucherRemove = _.remove(dataVoucher, function(n) { return n.id == key })
      if (voucherRemove[0].apply != 5 && voucherRemove[0].apply != 4) {
        handleClearVoucherDefault(dataVoucher, voucherRemove)
      } else {
        handleClearVoucherProduct(dataVoucher, dataPrice, voucherRemove[0])
      }
    } catch (error) {
      console.error(error)
      message.error("Có lỗi khi thêm voucher")
    }
  }

  const handleClearVoucherProduct = (dataVoucher, dataPrice, voucherRemove) => {
    let totalDiscount = 0
    let vcProductPriceItem = voucherProductPrice
    props.itemSelected.map((val, key) => {
      if (voucherRemove.packages.includes(String(val.product_id))) {
        let discount = 0
        if (voucherRemove.apply == 5) {
          discount = Number(voucherRemove.amount)
        } 
        if (voucherRemove.apply == 4) {
          discount = (val.price*Number(voucherRemove.amount/100)).toFixed()
        }
        totalDiscount += discount
        props.itemSelected[key].discount -= discount
      }
    })
    vcProductPriceItem -= totalDiscount

    setVoucherProductPrice(vcProductPriceItem)
     let voucherId = _.map(dataVoucher, "id")
      setListVoucher(dataVoucher.length > 0 ? dataVoucher : [])
      props.setLimitPaidOrder(dataVoucher.length > 0 ? dataVoucher[0].limit_paid_order : 0, true)
      setVoucher("")
      props.updateDiscountByRule({ amount: dataPrice, id: voucherId.join(","), totalAmount: dataPrice, vcProductPriceItem: vcProductPriceItem })
  }

  const handleClearVoucherDefault = (dataVoucher, voucherRemove) => {
    let voucherId = _.map(dataVoucher, "id")

    setListVoucher(dataVoucher.length > 0 ? dataVoucher : [])
    let dataPrice = handlePriceVoucher()
    props.setLimitPaidOrder(dataVoucher.length > 0 ? dataVoucher[0].limit_paid_order : 0, true)
    setVoucher("")
    props.updateDiscountByRule({ amount: voucherPrice, id: voucherId.join(","), totalAmount: dataPrice })
  }

  const handleShowList = () => {
    setVoucherList(!showVoucherList)
  }

  const handleShowAvatarList = (e) => {
    setShowCustomerAvatarList(e)
    if (e != 1) {
      setSelectAvatar(null)
    }
    if (props.showListHanet) {
      props.setOpenPopupHanet()
      return
    }
    let customer = props.currentPos.customers
    // console.log(checkChangeOrder,e)
    const timer =  setTimeout(() => {
       if (customer != undefined && showCustomerAvatarList != 2 && userAdmin.role == 3 && listActiveHanet) {
         setShowCustomerAvatarList(1);
       }
     }, 5000)
    setCheckChangeOrder(timer)
  }

  const saveAvatarCustomer = async (select) => {
    let customer = props.currentPos.customers
    let type = 1
    if (customer && customer.avata_url && customer.avata_url.length > 10) {
      type = 2
      var res = await axiosService("api/hanet-ai/update-customer/" + props.currentPos.customers.id, "POST", select)
    } else {
      var res = await axiosService("api/hanet-ai/add-customer/" + props.currentPos.customers.id, "POST", select)
    }

    if (res?.data?.success) {
      if (res?.data?.data?.returnCode === 1 && (res?.data?.data?.data?.path || res?.data?.data?.data?.file)) {
        setShowCustomerAvatarList(2)
        setSelectAvatar(null)

        if (type == 2) props.setOpenPopupHanet()

        props.currentPos.customers.avata_url = res?.data?.data?.data?.path ?? res?.data?.data?.data?.file
        props.editCustomerOrderEdit(res?.data?.data?.data?.path ?? res?.data?.data?.data?.file)

        message.success("Cập nhật avatar của khách thành công")
      } else {
        setShowCustomerAvatarList(1)
        message.error(res?.data?.data?.returnMessage)
      }
    } else {
      setShowCustomerAvatarList(1)
      message.error("cập nhật avatar của khách thất bại")
    }
  }

  const handleAddAvatarSelect = (select) => {
    setSelectAvatar(select)
  }

  const handleOpen = async () => {
    let customer = props.currentPos.customers
    let check = 0
    if (customer == undefined) return
    var listActiveHanets = listActiveHanet
    if (!checkCallApi) {
      const res = await axiosService("api/options/" + currentStore, "GET")
      if (res.data.success && res.data.data) {
        setListActiveHanet(res.data.data)
        listActiveHanets = res.data.data
      }
      setCheckCallApi(true)
    }
   
    if (customer && customer.avata_url && customer.avata_url.length > 10 || !listActiveHanets ||  userAdmin.role !=3 ) {
      setShowCustomerAvatarList(2)
      check = 2
    } else {
      setShowCustomerAvatarList(1)
    }
    // console.log(customer, check, showCustomerAvatarList, userAdmin.role, listActiveHanets)
    // if (check != 2 && showCustomerAvatarList != 2 && userAdmin.role == 3 && listActiveHanets) {
    //   setShowCustomerAvatarList(1);
    // }
  }

  const syncIPadScreen = async (type) => {
    let payload = {
      id: props.currentPos.id,
      screen: props.screen,
      discount: voucherPrice,
      status: type,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
      store: currentStore
    }

    let res = await axiosService("api/orders/sync-ipad-screen", "POST", payload)

    if (res?.data.success) {
      notifications({ type: 'success', mess: 'Cập nhật thành công' });
      setScreenStatus(res?.data.data.ipad_status)
      props.socket.emit("client-screen-ipad", {store_id: props.currentPos.stores.id, screen: props.screen});
    } else {
      notifications({ type: 'error', mess: res?.data.message });
    }
  }

  // Auto check combos when order items added
  useEffect(async () => {
    setListVoucher([])
    setVoucherPrice(0)
    setIsModalOpen(false)
    setVoucher("")
    setComboPrice(0)
    checkPrice()

    const filtersProduct = props.itemSelected.filter(x => {
      return x.price > 0 && x.product_id != null
    })
    var mapProductsArr = filtersProduct.map(x => {
      return x.product_id
    })
    setProductIds([...new Set(mapProductsArr)])
  }, [props.itemSelected, props.combos])

  useEffect(async () => {
    setIpadScreen(props.currentPos.ipad_screen)
    setScreenStatus(props.currentPos.ipad_status)
  }, [props.currentPos])

  useEffect(() => {
    checkPrice()
  }, [voucherPrice, voucherProductPrice])

  useEffect(() => {
    handleOpen();
    clearTimeout(checkChangeOrder);
  }, [props.itemSelected])

  return (
    <>
    <div className="order-checkout-block">
      <div className="order-total-subtotal price-order">
        <div>Thành tiền</div>
        <p className="ng-isolate-scope">
          <span className="cash-vnd ">
            {convert.convertCurrency(totalPrice.price)}
          </span>
        </p>
      </div>
      <div className="order-total-discount price-order text-danger">
        <div>
          Giảm giá
        </div>
        <p>
          <span className="text-danger" value="0">
            <span className="cash-vnd text-danger">
              {convert.convertCurrency(comboPrice)}
            </span>
          </span>
        </p>
      </div>
      <div className="order-total-discount price-order text-danger">
        <div>
          Đã thanh toán
        </div>
        <p>
          <span className="text-danger" value="0">
            <span className="cash-vnd text-danger">
              -{convert.convertCurrency(props.currentPos.paid_amount_before ?? 0)}
            </span>
          </span>
        </p>
      </div>

      {props.orderSelected.type == 3 ? 
        <div className="order-total-discount price-order text-danger">
          <div>
            Tiền thừa từ Upsale (Được cộng vào tài khoản khách hàng)
          </div>
          <p>
            <span className="text-danger" value="0">
              <span className="cash-vnd">
                +{convert.convertCurrency(props.currentPos.paid_amount_before - props.totalAmountUsingUpsale ?? 0)}
              </span>
            </span>
          </p>
       </div>
      : null}
      <div className="order-total-discount price-order text-pink">
        <div className="voucher-group">
          {listVoucher.length > 0 ?
            <>
               {props.tabSelect != 4 ? "Voucher:" : "Áp dụng quà tặng"}
                {listVoucher.map((item, key) => (
                  <div className="voucher-item" key={key}>
                    <span style={{ color: "black", fontSize: "11px" }}>{item.name} </span>
                    (<span style={{ color: "red", cursor: "pointer", textDecoration: "underline", fontSize: "11px"  }} onClick={() => handleClearVoucher(item.id)}>Xóa</span>)
                  </div>
              ))}
              {/* {listVoucher.length <= 1 ? 
                <span style={{ color: "#4a90e2", cursor: "pointer", textDecoration: "underline", fontSize: "11px"  }} className="ml-2" onClick={handLeOpenVoucher}>Thêm</span>
              : ''} */}
            </>
          : 
            props.tabSelect != 4 ? 
                <>
                  Thẻ giảm giá/voucher
                  <span className={`active-voucher ${totalPrice.price > 0 ? "" : "disable-package"}`} style={{ cursor: "pointer" }} onClick={handLeOpenVoucher}> {isModalOpen ? "Hủy" : ""}</span>
                </>
              : 
              < >
                <span style={{ color: "#0288D1", fontSize: "14px" }}>Tiền khuyến mại được cộng</span>
                <span className={`active-voucher ${totalPrice.price > 0 ? "" : "disable-package"}`} style={{ cursor: "pointer" }} onClick={handLeOpenVoucher}> {isModalOpen ? "Hủy" : ""}</span>
              </> 
          }

          <span className='d-flexs'>
            {/* <Input onChange={handleChangeTextVoucher} onPressEnter={onSubmitVoucher} autoFocus={true} placeholder="Vui lòng nhập một mã voucher" />
            <Button className="ml-4" icon={<CheckOutlined style={{ color: "green" }} onClick={onSubmitVoucher} />} /> */}
            <Button onClick={handleShowList}> { (props.tabSelect && props.tabSelect == 4) ? 'Ví khuyến mại thẻ tiền' : 'Ví Voucher'} </Button>
          </span>
        </div>
        <p>
          {props.tabSelect != 4 ?
             <span className="text-pink ng-isolate-scope" value="0">
             {voucherPrice > 0 && "-"}<span className="cash-vnd text-pink">
               {convert.convertCurrency(voucherPrice)}
             </span>
           </span>
          : 
            <span className=" ng-isolate-scope" value="0" style={{ color: "green" }}>
              {voucherPrice > 0 && "+"}<span className="cash-vnd " style={{ color: "green" }}>
                {convert.convertCurrency(voucherPrice)}
              </span>
            </span>
          }
        </p>
      </div>
      <div className="order-total-price price-order">
        <div style={{ fontSize: "20px" }}>
          Tổng tiền
        </div>
        <p className="text-primary font-20">
          <span className="cash-vnd  text-primary font-20">
            {convert.convertCurrency(finalPrice)}
          </span>
        </p>
      </div>
      <div className="order-total-checkout-buttons">
        <div className="button-container left">
          {_props.currentPos ?
            <div className={totalPrice.totalItems > 0 || _props.orderSelected.status === '4' ? "button-cent style3" : "button-cent style3 disable"}
              onClick={totalPrice.totalItems > 0 || _props.orderSelected.status === '4' ? _props.statusDeleteOrder : () => { }}>
              <span>
                <i className="fas fa-trash-alt"></i>
              </span>
              Hủy
            </div>
          : <div className={totalPrice.totalItems > 0 || _props.orderSelected.status === '4' ? "button-cent style3" : "button-cent style3 disable"}
              onClick={totalPrice.totalItems > 0 || _props.orderSelected.status === '4' ? _props.resetDataNewOrder : () => { }}>
              <span>
                <i className="fas fa-trash-alt"></i>
              </span>
              Hủy
            </div>
          }
        </div>
        <div className="button-container right">
          {[role.LT, role.ADMIN].includes(userAdmin.role) ?
            <>
              {ipadStatus && ipadStatus === 1 ? 
                <div className={"button-cent style3"}
                  onClick={() => syncIPadScreen(2)}
                  style={{marginRight: "10px"}}>
                  <span><i className="fas fa-times"></i></span>
                  Ẩn order iPad
                </div>
              :null}

              <div className={totalPrice.totalItems > 0 && _props.orderSelected.status === '4' ? "button-cent save-and-book" : "button-cent disable save-and-book"}
                onClick={totalPrice.totalItems > 0 && _props.orderSelected.status === '4' ?() => syncIPadScreen(1) : () => { }}
                style={{marginRight: "10px"}}>
                <span><i className="fas fa-print"></i></span>
                {ipadStatus === 0 ? "Hiển thị iPad" : "Cập nhật iPad"}
              </div>
            </>
          :null}
            

          <div className={totalPrice.totalItems > 0 && _props.orderSelected.status === '4' ? "button-cent" : "button-cent disable"}
            onClick={totalPrice.totalItems > 0 && _props.orderSelected.status === '4' ? _props.paymentOrder : () => { }} >
            <span>
              <i className="fas fa-money-bill"></i>
            </span>
            Thanh toán 
          </div>
        </div>
      </div>

      {/* {props.currentPos !== '' ? 
        <div style={{ display: "none" }}><TemplatePrintOrder ref={invoiceRef} currentPos={props.currentPos} transaction={[]}/></div>
      :null } */}
    </div>
    {showVoucherList ? 
      <PosPopupVoucherList
        statusButton={statusButton}
        setStatusButton={setStatusButton}
        handleShowList={handleShowList}
        customer={props.currentPos.customers}
        currentPos={props.currentPos}
        total={totalPrice.price}
        products={productIds}
        selectVoucher={onSubmitSelectVoucher}
        handleClearVoucher= {handleClearVoucher}
        onSubmitVoucher={onSubmitVoucher}
        handleChangeTextVoucher={handleChangeTextVoucher}
        listVoucher={[...listVoucher]}
        tabSelect={props.tabSelect}
        waxing_upgrade={props.waxing_upgrade}
      /> : null}

     {showCustomerAvatarList == 1 || props.showListHanet? 
      <PopupSelectAvatarCustomer 
        saveAvatarCustomer={saveAvatarCustomer}
        handleShowAvatarList = {handleShowAvatarList}
        customer={props.currentPos.customers}
        handleAddAvatarSelect= {handleAddAvatarSelect}
      /> : null}
      {selectAvatar ?
        <div className="avatar-view">
          <div  className="title-avatar">Date : {moment(new Date(selectAvatar.date)).format('YYYY-MM-DD HH:mm')}</div>
          <AvatarCustomer 
            voucher={selectAvatar}
          /> 
        </div>
      : null }
    </>
  );
}

export default PosOrderTotal;

