import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";

const ReportTvOnline = () => {
    const [title, setTiltle] = useState("Báo cáo tổng quan Sale")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalService, setTotalService] = useState(0)
    const [totalOrder, setTotalOrder] = useState(0)

    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
   
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/mysql/transaction/sales?start=${sD}&end=${eD}&store=${store}&keyword=${keyword}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {totalPaid, totalService, totalOrder, dataChart, dataTable } = data.data

            // setChart(dataChart)
            setTable(dataTable)
            setTotalPaid(totalPaid)
            setTotalService(totalService)
            setTotalOrder(totalOrder)

        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store,keyword])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const columns = [
        {
            title: 'Nhân viên',
            dataIndex: 'user_name',
            fixed: 'left',
            render: (data) => {
                return (
                    <>
                        <p>{data}</p>
                    </>
                )
            },
            sorter: (a, b) => a.countOrder.length - b.countOrder.length,
        },
        {
            title: 'Đơn thành công',
            dataIndex: 'count_order_ss',
            align: "center",
            sorter: {
                compare: (a, b) => a.countOrder - b.countOrder,
                multiple: 3,
            },
        },
        {
            title: 'Doanh số thành công',
            dataIndex: 'total_price',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(Number(data))}</p>
                )
            },
            sorter: {
                compare: (a, b) => Number(a.totalPriceOrder) - Number(b.totalPriceOrder),
                multiple: 1,
            },
        },
        {
            title: 'Tiền khách thanh toán',
            dataIndex: 'paid',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
            sorter: {
                compare: (a, b) => Number(a.totalPriceOrder) - Number(b.totalPriceOrder),
                multiple: 1,
            },
        },
        {
            title: 'Số lượng dịch vụ',
            dataIndex: 'countOrderItem',
            align: "center",
            sorter: {
                compare: (a, b) => a.countOrderItem - b.countOrderItem,
                multiple: 2,
            },
        },
        {
            title: 'Đơn hàng Thất bại',
            dataIndex: 'count_auto_cancel',
            align: "center",
            sorter: {
                compare: (a, b) => a.countOrder - b.countOrder,
                multiple: 3,
            },
        },

        {
            title: 'Tổng tiền đơn Thất bại',
            dataIndex: 'total_auto_cancel',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(Number(data))}</p>
                )
            },
            sorter: {
                compare: (a, b) => Number(a.totalPriceOrder) - Number(b.totalPriceOrder),
                multiple: 1,
            },
        },
        {
            title: 'Số đơn Pre Order',
            dataIndex: 'countOrder',
            align: "center",
            sorter: {
                compare: (a, b) => a.countOrder - b.countOrder,
                multiple: 3,
            },
        },
        {
            title: 'Doanh số Pre Order',
            dataIndex: 'totalPriceOrder',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(Number(data))}</p>
                )
            },
            sorter: {
                compare: (a, b) => Number(a.totalPriceOrder) - Number(b.totalPriceOrder),
                multiple: 1,
            },
        },
        {
            title: 'Giá trị tb đơn hàng',
            dataIndex: 'ratio',
            align: "center",
            render: (data) => {
                console.log(data)
                return (
                    <p className="text2">{convertCurrency(Number(data))}</p>
                )
            },
        },
        {
            title: 'Tiền thực thu',
            dataIndex: 'paidOrder',
            align: "center",
            render: (data) => {
                console.log(data)
                return (
                    <p className="text1">{convertCurrency(Number(data))}</p>
                )
            },
        },
        {
            title: 'Số booking',
            dataIndex: 'bookings',
            align: "center",
            sorter: {
                compare: (a, b) => a.bookings - b.bookings,
                multiple: 2,
            },
        },
    ];
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    return (
        <div className={`p-2 ${styles.background}`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                title={title} type={"saless"}
                csvData={table} isExport={isExport}
                hanldeExportData={hanldeExportData}
                onChangeKeyword={onChangeKeyword}
                header={
                    [
                        { label: 'Nhân viên', key: "user_name" },
                        { label: "Số đơn hàng", key: "countOrder" },
                        { label: "Số lượng dịch vụ", key: "countOrderItem" },
                        { label: "Doanh số tư vấn", key: `totalPriceOrder` },
                        { label: "Giá trị tb đơn hàng", key: `ratio` },
                        { label: "Tiền thực thu", key: `paidOrder` },
                        { label: "Số booking", key: `bookings` }
                    ]
                }
                userAdmin={userAdmin}
            />
            <div className="container-fluid">
                <Spin spinning={loading} tip="Đang tải...">
                    <Row>
                        <Col md={8} xs={12}>
                            <Row className="h-100">
                                <Col md={6} xs={12}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Tổng quan sale lên: </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                                <br />
                                                <br />
                                                <span className={styles.textTag4}>Tổng dịch vụ tư vấn:</span>
                                                <span className={styles.textTag4}>Tổng đơn hàng đã lên:</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(totalPaid)}</span>
                                                <br />
                                                <span className={styles.textTag2}> {totalService}</span>
                                                <span className={styles.textTag2}> {totalOrder}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12}>
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <div className="border-bottom w-100 mb-2">
                                    Bảng số liệu chi tiết
                                </div>
                                <Table 
                                    columns={columns} 
                                    dataSource={table}
                                    pagination={{ pageSize: 10 }}
                                    scroll={{ x: 1300 }}
                                    bordered
                                />
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div>
    )
}
export default ReportTvOnline