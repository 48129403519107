import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line } from '@ant-design/plots';
import { Spin } from "antd"
import getDates from "./getDates"
import { DataGrid } from '@mui/x-data-grid';
import "./scss/report.scss"
import StorageService from "../../utils/storage.service";
const ReportDaily = () => {
    const columns = [
        { field: 'id', headerName: 'STT', width: 50 },
        { field: 'date', headerName: 'Thời gian', },
        { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            field: 'revenue',
            headerName: 'Tiền thực thu',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.revenue), width: 170,
            cellClassName: (params) => "text1"
        },
        {
            field: 'owed',
            headerName: 'Nợ mới',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.owed), width: 140,
            valueGetter: (params) => convertCurrency(params.row.owed), width: 130,
            cellClassName: (params) => "text2"
        },
        {
            field: 'revenueOwe',
            headerName: 'Nợ đã thu',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.revenueOwe), width: 140,
            valueGetter: (params) => convertCurrency(params.row.revenueOwe), width: 130,
            cellClassName: (params) => "text1"
        },
        {
            field: 'totalReceiptThuan',
            headerName: 'Doanh số thuần',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.totalReceiptThuan), width: 140,
            valueGetter: (params) => convertCurrency(params.row.totalReceiptThuan), width: 130,

            cellClassName: (params) => "text7"
        },

        {
            field: 'deposit',
            headerName: 'Trừ tài khoản',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.deposit), width: 150,
            cellClassName: (params) => "text3"
        },
        {
            field: 'giftCashTotal',
            headerName: 'Trừ điểm tích luỹ',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.giftCashTotal), width: 150,
            valueGetter: (params) => convertCurrency(params.row.giftCashTotal), width: 130,
            cellClassName: (params) => "text3"
        },
        {
            field: 'voucher',
            headerName: 'Trừ Voucher',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.voucher), width: 130,
            cellClassName: (params) => "text3"
        },
        {
            field: 'combo',
            headerName: 'Trừ combo',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.combo), width: 130,
            cellClassName: (params) => "text3"
        },
        {
            field: 'totalReceiptBefore',
            headerName: 'Doanh thu',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.totalReceiptBefore), width: 150,
            cellClassName: (params) => "text4"
        },
    ];
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0]?.id
        : parseInt(StorageService.getItem("currentStore")) )
    const [sortBy, setSortBy] = useState(0)
    const [payByCash, setPayByCash] = useState({})
    const [payCredit, setPayCredit] = useState({})
    const [paySwipeCard, setPaySwipeCard] = useState({})
    const [totalProductOrder, setTotalProductOrder] = useState([])
    const [totalMyPham, setTotalMyPham] =   useState(0)
    const [totalDeposit, setTotalDeposit] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const [ordersByDays, setOrdersByDays] = useState([])
    const [receipt, setReceipt] = useState({})
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47, 53, 54, 1000])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [pageSize, setPageSize] = useState(10)

    const hanldeRangeDate = useCallback((sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }, [startDate, endDate])

    const hanldeStore = useCallback((e) => {
        setStore(e)
    }, [store])

    const hanldeSortBy = useCallback((e) => {
        setSortBy(e)
    }, [sortBy]);

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate)
    }, [store, endDate, startDate, sortBy])
    
    const fetchData = async (s, eD, sD) => {
        const rs = await axiosService(`api/reports/daily?store=${s}&startDate=${sD}&endDate=${eD}`, "GET")
        const { data } = rs
        setPayByCash(data.data.payByCash)
        setPayCredit(data.data.payCredit)
        setPaySwipeCard(data.data.paySwipeCard)
        setTotalOrders(data.data.totalOrders)
        setReceipt(data.data.totalAmountReceipt)
        let dataSort = []
        for (let item of data.data.totalProductOrder.allCategory) {
            item.sum_paid_order_item = Number(item.sum_paid_order_item)
            dataSort.push(item)
        }
        if (data.data.totalProductOrder.allCategory.length > 0) {
           
            let totalMyPham = 0
            let totalDeposit = 0
            for(let o of data.data.totalProductOrder.allCategory) {
                if (o.category_id && !listCategoryIds.includes(o.category_id)) {
                    totalMyPham += Number(o.sum_paid_order_item)
                }
                if (o.category_id == 50 || !o.category_id) {
                    totalDeposit += Number(o.sum_paid_order_item)
                }
            }
            dataSort.push({sum_paid_order_item: totalMyPham, category_id: 1000, category_name: "Mỹ Phẩm"})
            setTotalMyPham(totalMyPham)
            setTotalDeposit(totalDeposit)
        }
        dataSort = _.orderBy(dataSort, ['sum_paid_order_item'],
            ['desc']);
      
        setTotalProductOrder([...dataSort])

        if (data.code === 200) {
            let value = "yyyy-MM-dd"
            if (sortBy === 1) {
                value = "ww"
            } else if (sortBy === 2) {
                value = "MM"
            } else if (sortBy === 3) {
                value = "Q"
            }

            const dataTransaction = _.groupBy(data.data.ordersByDays.transaction, (x) => format(new Date(x.transaction_created_at), value))
            const dataRevenueOrder = _.groupBy(data.data.ordersByDays.revenueOrder, (x) => format(new Date(x.order_order_at), value))
            const grouped = _.groupBy(data.data.ordersByDays.order, (x) => format(new Date(x.order_at), value))
            const convertData = Object.entries(grouped);
            Promise.all(convertData.map(x => {
                return async.parallel({
                    totalReceipt: (cb) => {
                        const result = _.sumBy(x[1], function (o) { 
                            if (o.status === 3 || o.status === 4) {

                            if (o.status === 4) {
                                return o.total_price  - o.money_owed - o.deposit_total - o.gift_cash_total
                            }
                             return o.total_price  
                            } 
                            else { return 0 } 
                        });
                        cb(null, result)
                    },
                    totalReceiptThuan: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if (o.status === 3) { return o.total_price + o.total_amount_using_upsale  } else { return 0 } });
                        cb(null, result)
                    },
                    totalReceiptBefore: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if (o.status === 3) { return o.total_price_before  } else { return 0 } });
                        cb(null, result)
                    },
                    owedPaid: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3) { return o.money_owed  } else { return 0 } });
                        cb(null, result)
                    },
                    owed: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3) { return o.money_owed  } else { return 0 } });
                        cb(null, result)
                    },
                    deposit: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3) { return o.deposit_total  } else { return 0 } });
                        cb(null, result)
                    },
                    giftCashTotal: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3) { return o.gift_cash_total  } else { return 0 } });
                        cb(null, result)
                    },
                    totalOrder: (cb) => {
                        const count = _.filter(x[1], function (o) { return o }).length;
                        cb(null, count)
                    },
                    revenueOwe: (cb) => {
                        const count = _.sumBy(dataTransaction[x[0]], function (o) { return o.transaction_paid_amount });

                        cb(null, count)
                    },
                    revenueOrder: (cb) => {
                        const count = _.sumBy(dataRevenueOrder[x[0]], function (o) { return o.transaction_paid_amount });
                        cb(null, count)
                    },
                    voucher: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3 ) { return (o.discount_by_rule ?? 0 )  } else { return 0 } });
                        cb(null, result)
                    },
                    combo: (cb) => {
                        const result = _.sumBy(x[1], function (o) { if(o.status === 3 ) { return (o.discount_by_total_bill)  } else { return 0 } });
                        cb(null, result)
                    },
                }).then(rs => {
                    return { date: x[0], value: rs }
                })
            })).then(async (values) => {
                await Promise.all(values.map((x, i) => {
                    return {
                        id: i+1,
                        ...x.value,
                        date: x.date,
                        owed: (x.value.owed) ?? 0,
                        deposit: x.value.deposit,
                        gift_cash: x.value.giftCashTotal,
                        revenue: x.value.revenueOrder + x.value.revenueOwe,
                        revenueOwe: x.value.revenueOwe
                    }
                })).then(rs => {
                    setRows([...rs])
                })
                if (sortBy === 0) {
                    await Promise.all(getDates(new Date(sD), new Date(eD)).map(x => {
                        const index = values.findIndex((y) => y.date == x)
                        if (index >= 0) {
                            return { date: x, value: values[index].value.revenueOrder + values[index].value.revenueOwe }
                        } else {
                            return { date: x, value: 0 }
                        }
                    })).then(rs => {
                        setOrdersByDays(rs)
                    })
                } else {
                    await Promise.all(values.map(x => {
                        return { date: x.date, value: x.value.revenueOrder + x.value.revenueOwe}
                    })).then(rs => {
                        setOrdersByDays(rs)
                    })
                }
            });
            setLoading(false)
        }
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const config = useMemo(() => configChart(ordersByDays, convertCurrency), [ordersByDays])
    
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }

    const handelChange = (e) => {
        setPageSize(e)
    }
    return (
        <div className={`${styles.background} report-page`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                sortBy={sortBy} hanldeSortBy={hanldeSortBy}
                title={"Doanh số theo ngày"} type={"daily"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thời gian', key: "date" },
                        { label: "Số đơn hàng", key: "totalOrder" },
                        { label: "Tiền thực thu", key: "revenue" },
                        { label: "Nợ mới", key: "owed" },
                        { label: "Nợ đã thu", key: "revenueOwe" },
                        { label: "Doanh số thuần", key: "totalReceiptThuan" },
                        { label: "Trừ thẻ tiền", key: "deposit" },
                        { label: "Trừ điểm thưởng", key: "giftCashTotal" },
                        { label: "Trừ Voucher", key: "voucher" },
                        { label: "Trừ Combo", key: "combo" },
                        { label: "Doanh thu", key: "totalReceiptBefore" }
                    ]
                }
                userAdmin={userAdmin}
            />
            <div className="container-fluid">
                <Spin spinning={loading}>
                    <Row className="mt-2">
                        <Col xs={12} md={8}>
                            <Row className="h-100">
                                <Col xs={6} md={3}>
                                    <Tag color="blue" className="w-100 p-2 h-100">
                                        <span className={styles.textTag3}>Tổng tiền đã thu </span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}>
                                                    {convertCurrency(
                                                        (receipt?.revenueByOrder + receipt?.revenueByOwed) || 0)}</span>
                                            </div>
                                        </div>
                                        <span className={styles.textTag3}>Doanh số thuần</span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}> {convertCurrency(Number(receipt.totalByOrder?.sum_total_price ?? 0) + Number(receipt.totalByOrder?.sum_total_amount_using_upsale ?? 0) - Number(receipt.totalByOrderUpsale?.sum_total_price ?? 0))}</span>
                                            </div>
                                        </div>
                                        <span className={styles.textTag3}>Số đơn hàng</span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span className={styles.textTag}> </span>
                                            </div>
                                            <div className="ml-3">
                                                <span className={styles.textTag}> {totalOrders}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>

                                <Col xs={6} md={3} className="pay_type">                   
                                    <Tag color="violet" className="w-100 h-55 mb-3 ">
                                        <span className={styles.textTag3}>Thu tiền mặt</span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                {/* <span className={styles.textTag4}>Bán mới</span> */}
                                            </div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency((payByCash.total + payByCash.owed ) || 0)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                    <Tag color="green" className="w-100 h-55 mb-3" >
                                        <span className={styles.textTag3}>Thu chuyển khoản</span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                {/* <span className={styles.textTag4}>Bán mới</span> */}
                                            </div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency((payCredit.total + payCredit.owed)|| 0)}</span>
                                            </div>
                                        </div>
                                    </Tag>

                                    <Tag color="red" className="w-100 h-55">
                                        <span className={styles.textTag3}>Thu quẹt thẻ</span>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                {/* <span className={styles.textTag4}>Bán mới</span> */}
                                            </div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency((paySwipeCard.total + paySwipeCard.owed) || 0)}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} md={3}>
                                    <Tag color="red" className=" w-100 h-100 p-2">
                                        <p className={styles.textTag3}>TÌNH TRẠNG NỢ</p>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className={styles.textTag4}>Nợ mới</p>
                                                <p className={styles.textTag4}>Nợ đã thu</p>
                                            </div>
                                            <div>
                                                <p className={styles.textTag}>
                                                    {convertCurrency(Number(receipt?.totalByOrder?.sum_money_owed) - Number(receipt?.totalByOrderUpsale?.sum_money_owed))}</p>
                                                <p className={styles.textTag}>{convertCurrency((payByCash.owed + paySwipeCard.owed + payCredit.owed) || 0)}</p>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            
                                <Col xs={6}  md={3} className="pay_type">
                                    <Tag color="blue" className="w-100 h-55 mb-3 ">
                                        <span className={styles.textTag3}>Trừ tài khoản</span>
                                        <div className="d-flex justify-content-between">
                                            <div></div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_deposit_total || 0))}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                    <Tag color="violet" className="w-100 h-55 mb-3" >
                                        <span className={styles.textTag3}>Trừ điểm tích luỹ</span>
                                        <div className="d-flex justify-content-between">
                                            <div></div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_gift_cash_total || 0))}</span>
                                            </div>
                                        </div>
                                    </Tag>

                                    <Tag color="green" className="w-100 h-55">
                                        <span className={styles.textTag3}>Trừ voucher</span>
                                        <div className="d-flex justify-content-between">
                                            <div></div>
                                            <div>
                                                <span className={styles.textTag}>{convertCurrency(Number((receipt?.totalByOrder?.sum_total_price_before - receipt?.totalByOrder?.sum_total_price ) || 0))}</span>
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row className="mh-172">
                                <Col xs={6}>
                                    <Tag color="green" className=" w-100 h-100 p-2">
                                        <p className={styles.textTag3}>THỰC THU NHÓM DỊCH VỤ</p>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                            {totalProductOrder?.map((x,y) => (
                                                listCategoryIds.includes(x.category_id) ?
                                                    <p key={y} className={styles.textTag4}>{x.category_name }</p> : null
                                                ))
                                            }
                                            </div>
                                            <div>
                                                {totalProductOrder.map((x,y) => (
                                                    listCategoryIds.includes(x.category_id) ?
                                                        <p key={y} className={styles.textTag3}>{convertCurrency(x.sum_paid_order_item || 0)}</p> : null

                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6}>
                                    <Tag color="blue" className=" w-100 h-100 p-2">
                                        <p className={styles.textTag3}>Tiền Nạp Vào Tài Khoản</p>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                
                                                <p className={styles.textTag4}>Tiền nạp vào tài khoản</p>
                                                
                                            </div>
                                            <div>
                                                <p className={styles.textTag3}>{convertCurrency(totalDeposit || 0)}</p>
                                            
                                            </div>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={6}>
                                    <Tag className={`${styles.tag2} p-2 w-100 h-100`} color="red">
                                        <div className="d-flex justify-content-start">
                                            <p className={styles.textTag3}>Triệt lông </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_deposit_total || 0))}</p>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6}>
                                    <Tag color="green" className="p-2 w-100 h-100">
                                        <div className="d-flex justify-content-start">
                                            <p className={`${styles.textTag3}`} >Nails</p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(0)}</p>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row> */}
                            {/* <Row className="mt-1">
                                <Col xs={6} className="pb-1">
                                    <Tag className={` ${styles.textTag} p-2 w-100 h-100`} color="violet">
                                        <div className="d-flex justify-content-start">
                                            <p className={`${styles.textTag3}`} >Chăm sóc da </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_gift_cash_total || 0))}</p>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} className="pb-1">
                                    <Tag className={` ${styles.textTag} p-2 w-100 h-100`} color="violet">
                                        <div className="d-flex justify-content-start">
                                            <p className={`${styles.textTag3}`} >Mi</p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(Number((receipt?.totalByOrder?.sum_total_price_before - receipt?.totalByOrder?.sum_total_price ) || 0))}</p>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} className="pb-1">
                                    <Tag className={`${styles.tag3} p-2 w-100 h-100`} color="green">
                                        <div className="d-flex justify-content-start">
                                            <p className={`${styles.textTag3}`} >Phun xăm </p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(0)}</p>
                                        </div>
                                    </Tag>
                                </Col>
                                <Col xs={6} className="pb-1">
                                    <Tag className={`${styles.tag3} p-2 w-100 h-100`} color="green">
                                        <div className="d-flex justify-content-start">
                                            <p className={`${styles.textTag3}`} >Gội đầu</p>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <p className={styles.textTag}>{convertCurrency(0)}</p>
                                        </div>
                                    </Tag>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                    <Row className="mt-2" style={{alignItems: 'end'}}>
                        <Col xs={12} md={5} className="hide-mobile">
                            <div style={{ backgroundColor: "white" }} className="p-2">
                                <Line {...config} />
                            </div>
                        </Col>
                        <Col xs={12} md={7}>
                            <div style={{ backgroundColor: "white" }} >
                                <div className="border-bottom mb-1 p-2">
                                    <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                                </div>
                                <div style={{ height: 495, width: '100%' }} className="p-2">
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={pageSize}
                                        rowsPerPageOptions={[10,15,30,100]}
                                        onPageSizeChange={handelChange}

                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </div>
        </div >

    )
}
const configChart = (data, convertCurrency) => {
    const config = {
        data: data,
        padding: 'auto',
        xField: 'date',
        yField: 'value',
        height: 520,
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.date,
                    value: convertCurrency(data.value)
                };
            },
        },
        slider: {
            start: 0,
            end: 1,
        },

        xAxis: {
            label: {
                autoRotate: true,
                style: {
                    fontSize: 10
                },
            },
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        point: {
            size: 5,
            shape: 'circle',
            style: {
                fill: 'blue',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },

    };
    return config
}
export default ReportDaily