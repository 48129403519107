export const optionsDays = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
];

export const optionsMonths = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
]

export const optionsHoursBooking = [
    { value: 9, label: '9 giờ' },
    { value: 10, label: '10 giờ' },
    { value: 11, label: '11 giờ' },
    { value: 12, label: '12 giờ' },
    { value: 13, label: '13 giờ' },
    { value: 14, label: '14 giờ' },
    { value: 15, label: '15 giờ' },
    { value: 16, label: '16 giờ' },
    { value: 17, label: '17 giờ' },
    { value: 18, label: '18 giờ' },
    { value: 19, label: '19 giờ' },
    { value: 20, label: '20 giờ' },
]

export const optionsMinuteBooking = [
    { value: 0, label: '0 phút' },
    { value: 5, label: '5 phút' },
    { value: 10, label: '10 phút' },
    { value: 15, label: '15 phút' },
    { value: 20, label: '20 phút' },
    { value: 25, label: '25 phút' },
    { value: 30, label: '30 phút' },
    { value: 35, label: '35 phút' },
    { value: 40, label: '40 phút' },
    { value: 45, label: '45 phút' },
    { value: 50, label: '50 phút' },
    { value: 55, label: '55 phút' },
]

export const optionsGender = [
    { value: 1, label: 'Nam' },
    { value: 2, label: 'Nữ' },
]

export const customStylesBooking = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    control: (provided, state) => ({
      ...provided,
      width: 130,
      textAlign: "center",
      minHeight: 32,
      paddingLeft: 0, 
      paddingRight: 0, 
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: 1
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight =  0; 
      return { ...provided, opacity, transition, marginLeft, marginRight };
    },
}

export const customStylesFilter = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      width: 120,
      textAlign: "center",
      minHeight: 32,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition };
    },
}

export const customStylesOption = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      height: 32,
      paddingLeft: 0, 
      paddingRight: 0,
      outline: "none",
      boxShadow: "none"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
      lineHeight: '32px'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight =  0; 
      const height = 32; 
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    }
}

export const customStylesMultiSelect = {
  control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0, 
      paddingRight: 0, 
  }),
  valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
  }),
  placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
  }),
  multiValue: (provided, state) => ({
      ...provided,
      height: 30,
      lineHeight: '25px',
      backgroundColor: '#4a90e2',
      color: '#fff'
  }),
  multiValueLabel: (provided, state) => ({
      ...provided,
      color: '#fff',
      fontSize: '12px'
  }),
  input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
  })
}

export const optionsStatusBooking = [
  { value: 1, label: 'Chưa xác nhận' },
  { value: 2, label: 'Đã xác nhận' },
  { value: 3, label: 'Chờ phục vụ' },
  { value: 4, label: 'Đang phục vụ' },
  { value: 5, label: 'Hủy lịch' },
  { value: 6, label: 'Không đến' },
  { value: 7, label: 'Hoàn thành' },
]

export const optionsCountBooking = [
  { value: 0, label: "Status booking"},
  { value: 2, label: 'Đã xác nhận' },
  { value: 3, label: 'Chờ phục vụ' },
  { value: 4, label: 'Đang phục vụ' },
  { value: 7, label: 'Hoàn thành' },
]

export const optionsStatusBookingForCS = [
  { value: 1, label: 'Chưa xác nhận' },
  { value: 2, label: 'Đã xác nhận' },
  { value: 5, label: 'Hủy lịch' },
]

export const optionsViewOrderRemover = [
  { value: 1, label: 'Xem lịch' },
  // { value: 2, label: 'Xoá lịch' },
]

export const optionsSourceBooking = [
  { value: 1, label: 'Gọi điện' },
  { value: 2, label: 'FB Messenger' },
  { value: 3, label: 'Instagram' },
  { value: 4, label: 'Zalo' },
  { value: 5, label: 'Website' },
  { value: 6, label: 'Từ cơ sở' }
]

export const optionsPaymentMethods = [
  { value: 'Tiền mặt', label: 'Tiền mặt' },
  { value: 'Chuyển khoản', label: 'Chuyển khoản' },
  { value: 'Quẹt thẻ', label: 'Quẹt thẻ' },
  // { value: 'Điểm tích luỹ', label: 'Điểm tích luỹ' },
  { value: 'Nhiều hình thức', label: 'Nhiều hình thức' },
  // { value: 'Khuyến mại', label: 'Khuyến mại' },
  // { value: 'Zalo Pay', label: 'Zalo Pay' },
  // { value: 'Momo', label: 'Momo' },
  // { value: 'Grab Pay', label: 'Grab Pay' },
  // { value: 'VN Pay', label: 'VN Pay' },
  // { value: 'Samsung Pay', label: 'Samsung Pay' },
  // { value: 'AirPay', label: 'AirPay' },
  // { value: 'VinID', label: 'VinID' },
  // { value: 'Gotlt', label: 'Gotlt' },
  // { value: 'Ship COD', label: 'Ship COD' },
]

export const optionsPaymentMethods2 = [
  { value: 'Nhiều hình thức', label: 'Nhiều hình thức' },
]


export const paymentMethodManyForms = [
  { value: 'Tiền mặt', label: 'Tiền mặt' },
  { value: 'Chuyển khoản', label: 'Chuyển khoản' },
  { value: 'Quẹt thẻ', label: 'Quẹt thẻ' },
  // { value: 'Điểm tích luỹ', label: 'Điểm tích luỹ' },
  // { value: 'Khuyến mại', label: 'Khuyến mại' },
  // { value: 'Zalo Pay', label: 'Zalo Pay' },
  // { value: 'Momo', label: 'Momo' },
  // { value: 'Grab Pay', label: 'Grab Pay' },
  // { value: 'VN Pay', label: 'VN Pay' },
  // { value: 'Samsung Pay', label: 'Samsung Pay' },
  // { value: 'AirPay', label: 'AirPay' },
  // { value: 'VinID', label: 'VinID' },
  // { value: 'Gotlt', label: 'Gotlt' },
  // { value: 'Ship COD', label: 'Ship COD' },
]

export const optionsRanking = [
  { value: 1, label: 'Member' },
  { value: 2, label: 'Silver' },
  { value: 3, label: 'Gold' },
  { value: 4, label: 'Platinum' },
]
export const accountPayment = [
  { value: '555.588.5585 - Ngân hàng MB Bank - TRAN HONG ANH', label:  '555.588.5585 - Ngân hàng MB Bank - TRAN HONG ANH', store: 1 },
  { value: '8686.8688.55555 - Ngân hàng MB Bank - NGUYEN XUAN HUYNH', label:  '8686.8688.55555 - Ngân hàng MB Bank - NGUYEN XUAN HUYNH', store: 3 },
  { value: '698.170.92000 -  Ngân hàng MB Bank - NGUYEN THUY LINH', label: '698.170.92000 -  Ngân hàng MB Bank - NGUYEN THUY LINH',  store: 6 },
  { value: '855.665.5555 - Ngân hàng MB Bank - Tạ Hà Phương', label: '855.665.5555 - Ngân hàng MB Bank - Tạ Hà Phương', store: 2 },
  { value: '366.886.666.66 - Ngân hàng TP Bank - Bùi Thị Thu Trang', label: '366.886.666.66 - Ngân hàng TP Bank - Bùi Thị Thu Trang',  store: 7 },
  { value: '366.886.666.66 - Ngân hàng TP Bank - Cơ sở Test', label: '366.886.666.66 - Ngân hàng TP Bank - Cơ sở Test',  store: 8 },
]

export const accountSwipe = [
  { value: '03118566901 - Ngân hàng TP BANK - TẠ HÀ PHƯƠNG', label:  '03118566901 - Ngân hàng TP BANK - TẠ HÀ PHƯƠNG', store: 1 },
  { value: '01724980401 - Ngân hàng TP BANK - TRẦN HỒNG ANH', label: '01724980401 - Ngân hàng TP BANK - TRẦN HỒNG ANH', store: 2 },
  { value: '55665856202 - Ngân hàng TP BANK - NGUYỄN XUÂN KHÁNH', label:  '55665856202 - Ngân hàng TP BANK - NGUYỄN XUÂN KHÁNH',  store: 3 },
  { value: '00001077278 - Ngân hàng TP Bank - PHAN THỊ MAI', label: '00001077278 - Ngân hàng TP Bank - PHAN THỊ MAI', store: 6},
  { value: '00002182109 - Ngân hàng TP Bank - BÙI THỊ THU TRANG', label: '00002182109 - Ngân hàng TP Bank - BÙI THỊ THU TRANG',store: 7 },
  { value: 'Test - Ngân hàng TP Bank - Cơ sở test', label: '00002182109 - Ngân hàng TP Bank - Cơ sở test',store: 8 },
]

export  const filterByStatus = [
  { value: "Tiền mặt", label: "Tiền mặt" },
  { value: "Chuyển khoản", label: 'Chuyển khoản' },
  { value: 'Quẹt thẻ', label: 'Quẹt thẻ' },
  { value: "Sử dụng thẻ tiền", label: "Sử dụng thẻ tiền" }
]

export  const selectAllElectronic = [
  { value: "Zalo Pay", label: "Zalo Pay" },
  { value: "Momo", label: 'Momo' },
  { value: 'Grap Pay', label: 'Grap Pay' },
  { value: 'VN Pay', label: 'Vn Pay' },
  { value: 'Samsung Pay', label: 'Samsung Pay' },
  { value: 'AriPay', label: 'AriPay' },
  { value: 'VinID', label: 'VinID' },
  { value: 'GotIt', label: 'GotIt' },
]

export  const optionLevel = [
  { value: 1, label: "Level 1" },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' }
]

export const accountPaymentQR = [
  { bank_key: 970422, bank_name : "Ngân hàng TMCP Quân đội", short_name: 'MBBank', bank_number: 655665555, account_name: "Tạ Hà Phương", store: 1, store_name: "Trần Duy Hưng" },
  { bank_key: 970422, bank_name : "Ngân hàng TMCP Quân đội", short_name: 'MBBank', bank_number: 8556655555, account_name: "Tạ Hà Phương", store: 2, store_name: "Võ Văn Dũng" },
  { bank_key: 970441, bank_name : "Ngân hàng TMCP Quốc tế Việt Nam", short_name: 'VIB', bank_number: 212250999, account_name: "Nguyễn Xuân Khánh", store: 3, store_name: "Nguyễn Văn Huyên" },
  { bank_key: 970423, bank_name : "Ngân hàng TMCP Tiên Phong", short_name: 'TPBank', bank_number: 8683886666, account_name: "Phan Thị Mai",  store: 6, store_name: "Minh Khai" },
  { bank_key: 970423, bank_name : "Ngân hàng TMCP Tiên Phong", short_name: 'TPBank', bank_number: 36688666666, account_name: "Bùi Thị Thu Trang", store: 7, store_name: "Hà Đông" },
  { bank_key: 970422, bank_name : "Ngân hàng TMCP Quân đội", short_name: 'MBBank', bank_number: 8686868855555, account_name: "NGUYEN XUAN HUYNH", store: 8 },
]