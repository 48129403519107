import React, { useState, useEffect, memo } from "react"
import { DateRangePicker, SelectPicker, Dropdown, Input, DatePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays, subWeeks, startOfWeek, endOfWeek, addWeeks, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import axiosService from "../../utils/axios.service";
import { format } from "date-fns"
import { Button } from 'antd';
import 'antd/dist/antd.min.css'
import headerReportEnum from "../../enums/headerReport.enum";
import { CSVDownload } from "react-csv";
import * as convert from "../constant/Convert.helper";
import _ from "lodash";


const Search = ({ startDate, endDate, hanldeRangeDate, store, hanldeStore, hanldeUser, hanldeEmulation, hanldeSearchVoucher, sortBy, hanldeSortBy,
    title, type, isExport, csvData, hanldeExportData, header, fileName, userAdmin, onChangeKeyword, emulation}) => {
    const [locations, setLocations] = useState([])
    const [locationsWhidth, setLocationsWhidth] = useState(window.innerWidth)

    const [sortByList, setSortByList] = useState(['Ngày', "Tuần", 'Tháng', 'Quý'].map(
        (x, i) => ({ label: x, value: i })
    ))
    const [keyword, setKeyword] = useState('')
    let startOfWeek1 = startOfWeek(new Date())
    let endOfWeek1 = endOfWeek(new Date())
    let ranges = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần trước",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng trước",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
        {
            label: "Năm này",
            value: [startOfYear(new Date()), endOfDay(endOfYear(new Date()))],
        },
        {
            label: "Năm trước",
            value: [startOfYear(addDays(startOfYear(new Date()), -1)), endOfYear(addDays(startOfYear(new Date()), -1))],
        },
    ]
    let rangesNv = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần trước",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng trước",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
    ]
    useEffect(() => {
        if (["daily", "customers", "emulation", "users", "products", "sales", "saless", "ktv-tvv", "detail-ktv-tvv"].includes(type)) {
            fetchLocations()
        }
        if (type == 'detail_promotion') {
            fetchGroupVoucher()
        }
    }, [sortBy])
    const onChangeDate = (e) => {
        if (e) {
            const eDate = format(e[1], 'yyyy-MM-dd')
            const sDate = format(e[0], 'yyyy-MM-dd')
            hanldeRangeDate(sDate, eDate)
        }
    }
    const fetchLocations = async () => {
        if (userAdmin && [3, 4].includes(userAdmin.role)) {
            let dataStore = convert.getStoreByRole(userAdmin)
            setLocations([...dataStore])
        } else {
            var rs = await axiosService("api/users/store/" + store + "?type=1", "GET")
            if (type == 'sales') {
                var rs = await axiosService("api/users/store/" + store +"?role=5", "GET")
            } else {
                var rs = await axiosService("api/users/store/" + store, "GET")
            }
            const { data } = rs
            if (data.code === 200) {
                const newLocations = data.data.map(x => {
                    return { label: x.name, value: x.id }
                })
                if (type == "emulation") {
                    setLocations([{label: "Tất cả", value: 0},...newLocations])
                } else {
                    setLocations([...newLocations])
                }
            }
        }
    }

    const fetchGroupVoucher = async () => {
        const rs = await axiosService("api/category_voucher", "GET")
        const { data } = rs
        if (data.code === 200) {
            data.data.unshift({ group_name: "Tất cả", id: 0 })
            const newLocations = data.data.map(x => {
                return { label: x.group_name, value: x.id }
            })
            setLocations([...newLocations])
        }
    }
    const onChangeSortBy = (e) => {
        hanldeSortBy(e)
    }
    const onChangeVoucherName = _.debounce((e) => {
        if (e.length > 3) {
            hanldeSearchVoucher(e)
        }
    }, 1000)

    const onChangeKeywordHeader = _.debounce((e) => {
        if (e.length > 3 || e.length == 0) {
            setKeyword(e)
            onChangeKeyword(e)
        }
    }, 1000)

    const onChangeUser = (e) => {
        for (let item of locations) {
            if (item.value == e) {
                hanldeUser(item)
            }
        }
    }
    const onChangeEmulation = (e) => {
        for (let item of emulation) {
            if (item.value == e) {
                hanldeEmulation(item)
            }
        }
    }

    const hanldeExport = () => {
        hanldeExportData()
    }
    return (
        <div className="search-report container-fluid">
            <Row>
                <Col xs={12} md={4}>
                    {userAdmin.role == 1 ?
                        <Dropdown title={title}>
                        {
                            headerReportEnum.map((x, i) => {
                                return (
                                    <Link to={{ pathname: `/report/${x.route}` }} key={i}>
                                        <Dropdown.Item >
                                            <i className={`${x.icon} mr-1`}></i><span>{x.lable}</span> {type === x.route && <i className="ml-2 fa-solid fa-check"></i>}
                                        </Dropdown.Item>
                                    </Link>
                                )
                            })
                        }
                        </Dropdown>
                    : null}
                </Col>
                <Col xs={12} md={8}>
                    <Row>
                        {userAdmin.role == 1 ?
                            <Col xs={12} md={3} xl={3} className="mb-2">
                                {["detail-ktv-tvv", "sales", "emulation"].includes(type) &&
                                    <SelectPicker data={locations} className="w-100" defaultValue={userAdmin.id} onChange={onChangeUser}/>
                                }
                            </Col>
                        : null }
                         {type == "emulation" || type == "hopper"? 
                                <Col xs={12} md={3} xl={3} className="mb-2">
                                {["emulation", "hopper"].includes(type) &&
                                    <SelectPicker data={emulation} className="w-100" defaultValue={0} onChange={onChangeEmulation}/>
                                }
                                </Col>
                         : null}
                        
                        {type != "emulation" && type != "hopper"? 
                            <Col xs={12} md={3} xl={3}  className="mb-2">
                                <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeywordHeader} />
                            </Col>
                        : null}

                        {type != "emulation" ? 
                            <Col xs={12} md={6} xl={5} className="mb-2">
                                <DateRangePicker
                                    className="w-100"
                                    placement={"bottomEnd"}
                                    format="yyyy-MM-dd"
                                    onChange={onChangeDate}
                                    ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                                    defaultValue={[new Date(startDate), new Date(endDate)]}
                                    showOneCalendar={locationsWhidth < 768}
                                />
                            </Col>
                        : null}


                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default memo(Search)