import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Search from "./Search"
import Header from "../Header";
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin, Tabs } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import PopupViewServices from "./PopupViewServices"
import ViewDetailSaleTvvCtmByDay from "./ViewDetailSaleTvvCtmByDay"
import ViewDetailServiceUser from "./ViewDetailServiceUser"
import ViewDetailOrderServiceUser from "./ViewDetailOrderServiceUser"
import ViewDetailOrderSaleTvvCtm from "./ViewDetailOrderSaleTvvCtm"
import ViewOrderNoTvv from "./ViewOrderNoTvv"


const queryParams = new URLSearchParams(window.location.search)
const type = queryParams.get("type")


const { TabPane } = Tabs;

const ReportSaleTvCtm = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isView, setIsView] = useState(false);
    const [totalComKtv, setTotalComKtv] = useState(0);
    const [dataViewService, setDataViewService] = useState([])
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [tableByDay, setTableByDay] = useState([])
    const [keyword, setKeyword] = useState([])
    const [totalComTvv, setTotalComTvv] = useState(0)
    const [com, setCom] = useState(0)
    const [countOrderNew, setCountOrderNew] = useState(0)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [token, setToken] = useState('')
    const [userId, setUserId] = useState(userAdmin.id)
    const [userName, setUserName] = useState(userAdmin.name)
    const [orderNo, setOrderNo] = useState([])
    const [aov, setAov] = useState(0)


    useEffect(() => {
        setTiltle("Chi tiết Sale")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }


    const hanldeUser = (x) => {
        setUserId(x.value)
        setUserName(x.label)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/sale/online/report-sale-online-tv-new/all?type=1&user_id=${userId}&user_name=${userName}&start=${sD}&end=${eD}&keyword=${keyword}&role=${userAdmin.role}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {totalPaid, countSaleTvv, totalPaidSale, countKtvTv, totalPaidKtv, dataChart, dataTable, dataTableByDay, orderNo, com} = data.data
            // setChart(dataChart)
            setTable(dataTable)
            setTotalComTvv(totalPaid)
            setCom(com)
            setTableByDay(dataTableByDay)
            setCountOrderNew(countKtvTv + countSaleTvv)
            setOrderNo(orderNo)
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword, userId])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const showPopupViewService = (e) => {
        setIsView(!isView)
        setDataViewService(e)
        let totalCom = 0
        for (let item of e) {
            totalCom += item.total
        }
        setTotalComKtv(totalCom)
    }

    const handleIsView = (e) => {
        setIsView(!isView)
    }
   
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    function callback(key) {
        console.log(key);
    }
    return (
        <>
            <div className={`${styles.background}`}>
                <Search startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate} store={store}
                    hanldeStore={hanldeStore}
                    hanldeUser={hanldeUser}
                    title={title} type={"sales"}
                    csvData={table} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    onChangeKeyword={onChangeKeyword}
                    header={
                        [
                            { label: 'Nhân viên', key: "name" },
                            { label: 'Ngày thanh toán', key: "date" },
                            { label: "Hoá đơn", key: "order_code" },
                            { label: "Mã giao dịch", key: "transaction_code" },
                            { label: "Tên khách hàng", key: `customer_name` },
                            { label: "Số điện thoại", key: `customer_mobile` },
                            { label: "Sản phẩm", key: `product_name` },
                            { label: "Thực thu", key: `paid` },
                            { label: "Cơ sở", key: `store_name` },
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <div className="pl-2 pr-2">
                    <Spin spinning={loading} tip="Đang tải...">
                        <Row>
                            <Col xs={12} xl={8}>
                                <Row className="h-100">

                                    <Col xs={6} md={3} className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Thực thu tư vấn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalComTvv)}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={3} className="mb-2">
                                        <Tag color="violet" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tổng số đơn mới: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {countOrderNew}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={3} className="mb-2">
                                        <Tag color="green" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Hoa hồng tư vấn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                    {convertCurrency(com)}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    {/* <Col xs={6} md={3} className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tỷ lệ chốt đơn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span  className={styles.textTag5}> Số đơn Pv:</span>
                                                    <span  className={styles.textTag5}> Số đơn Tv:</span>
                                                    <span className={styles.textTag5}> Tỷ lê:</span>
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                    {totalOrderKtv}</span>
                                                    <span className={styles.textTag}>
                                                    {totalOrderTvv}</span>
                                                    <span className={styles.textTag}>
                                                        {aov} %</span>

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Tabs defaultActiveKey="1" onChange={callback}>
                                    <TabPane tab="Chi tiết đơn tư vấn" key="2">
                                        <ViewDetailOrderSaleTvvCtm
                                            table = {table}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        >
                                        </ViewDetailOrderSaleTvvCtm>
                                    </TabPane>
                                    <TabPane tab="Thực thu tư vấn theo ngày" key="4">
                                        <ViewDetailSaleTvvCtmByDay
                                            table = {tableByDay}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        />
                                    </TabPane>
                                    <TabPane tab="Đơn khách còn nợ" key="5">
                                        <ViewOrderNoTvv
                                            table = {orderNo}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Spin>
                </div>

            </div>
            {isView ?
               <>
                <PopupViewServices
                    show={isView}
                    close={handleIsView}
                    dataViewService={dataViewService}
                    totalComKtv={totalComKtv}
                >
                </PopupViewServices>
                </>
            : null}
        </>
    )
}
export default ReportSaleTvCtm