import { Popover, Whisper, Button, Toggle } from 'rsuite';
import { Checkbox, Divider, message } from 'antd';
import moment from "moment"
import { Row, Col, Container } from "react-bootstrap"
import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { filter } from 'lodash';
export default function PackageFullBody({ data: { label, options, parent, last_used, package_type_using }, onChangeItemFullBody, index, indexParent }) {
    const [checkAll, setCheckAll] = useState(false)
    const [packageSubs, setPackageSubs] = useState([])
    const [width, setWidth] = useState()

    const convertDateUse = (diffDate, last_used) => {
        var date = new Date(moment(new Date(last_used)).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
        date.setDate(date.getDate() + diffDate)
        const dateUse = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        return dateUse
    }

    const onCheckAllChange = () => {
        setCheckAll(!checkAll)
        packageSubs.forEach(x => {
            if (!x.disabled) {
                x.isSelect = !checkAll
            }
        })
        setPackageSubs([...packageSubs])
    }

    const handleChange = (y, i) => {
        const select = !packageSubs[i].isSelect
        packageSubs[i].isSelect = select
        const filter = packageSubs.filter(x => {
            return x.isSelect == select
        })
        const filterTrue = packageSubs.filter(x => {
            return x.isSelect == true
        })

        const filterValidPackages = packageSubs.filter(x => {
            return !x.disabled
        })
        
        
        if (filter.length == filterValidPackages.length && !package_type_using) {
            if (select) {
                setCheckAll(true)
            } else {
                setCheckAll(false)
            }
        } else {
            setCheckAll(false)
        }

        let newPackageSubs = []
        if (package_type_using) {
            for (let item of packageSubs) {
                if ( filterTrue.length >= last_used) {
                    if (!item.isSelect) {
                        item.disabled = true
                    }
                } else {
                    if (!item.checkDate) {
                        item.disabled = false
                    }
                }
                newPackageSubs.push(item)
            }
        } else {
            newPackageSubs = [...packageSubs]
        }

        setPackageSubs([...newPackageSubs])
    }

    const handleConfirm = () => {
        try {
            const list = lengthSelect
            if (list.length < 0) {
                onChangeItemFullBody(index, [], indexParent)
            } else {
                if (packageSubs.length > 0) {
                    let booking_item = [],
                    new_service = [],
                    intend_time = 0;
                    if (list.length === packageSubs.length && !package_type_using) {
                        new_service.push({
                            id: parent.value,
                            product_name: parent.product_name,
                            package_code: parent.package_code,
                            left_use: parent.left_use,
                            price: 0,
                            product_id: parent.product_id,
                            category_id: parent.product.category_id,
                            parent_package:parent.parent_package
                        })
                        intend_time = Number(parent.time) + 5
                    } else {
                        list.forEach(function (item) {
                            new_service.push({
                                id: item.value,
                                product_name: item.product_name,
                                package_code: item.package_code,
                                left_use: item.left_use,
                                price: 0,
                                product_id: item.product_id,
                                category_id: item.product.category_id,
                                parent_package:item.parent_package
                            })
                            intend_time += Number(item.time)
                        })

                        intend_time = (list.length == 0) ? 0 : (intend_time + 5);
                    }

                    intend_time = (intend_time > 60) ? 60 : intend_time;
                    booking_item.product_ids = new_service;
                    booking_item.intend_time = intend_time
                    booking_item.parent = indexParent
                    onChangeItemFullBody(index, booking_item, indexParent)
                }
            }
        } catch (error) {
            console.error(error)
            message.error("Lỗi khi thêm")
        }
    }

    const lengthSelect = useMemo(() => {
        const filter = packageSubs.filter(x => x.isSelect)
        return filter
    }, [packageSubs])

    useEffect(() => {
        handleConfirm()
    }, [lengthSelect])

    useEffect(() => {
        const filter = options.filter(x=>{
            return x.isSelect
        })
        if(filter.length === options.length && !package_type_using){
            setCheckAll(true)
        }
        let newPackageSubs = []
        if (package_type_using) {
            for (let item of options) {
                if ( filter.length >= last_used) {
                    if (!item.isSelect) {
                        item.disabled = true
                    }
                } else {
                    if (!item.checkDate) {
                        item.disabled = false
                    }
                }
                newPackageSubs.push(item)
            }
        } else {
            newPackageSubs = [...options]
        }

        setPackageSubs([...newPackageSubs])
    }, [options])
    
    return (
        <>
            <Whisper trigger="click" placement={"top"} onClose={handleConfirm} speaker={(props, ref) => {
                const { className, left, top, onClose } = props;
                return <Popover style={{ left, top, width: (window.innerWidth > 768) ? "40%" : "100%" }} className={className} ref={ref} title={label}>
                        <div onClick={(e) => {
                            e.stopPropagation()
                        }}>
                            <Checkbox onChange={(e) => {
                                onCheckAllChange()
                            }} checked={checkAll} disabled={(last_used < packageSubs.length && package_type_using) ? true : false}>
                                Chọn tất cả
                            </Checkbox>
                            <Divider />
                            <Container fluid>
                                <Row>
                                    {packageSubs.map((x, i) => {
                                        if (!x.disabled) {
                                            return <Col xs={6} key={i}>
                                                <Checkbox checked={x.isSelect} onChange={() => {
                                                    handleChange(x, i)
                                                }}>{x.product_name}</Checkbox>
                                                <br />
                                            </Col>
                                        } else {
                                            return <Whisper key={i} placement="top" trigger="focus" controlId="control-id-focus" speaker={
                                                <Popover title="Chú ý">
                                                    <p>{`Còn ${x.dateDiff} ( ${convertDateUse(x.dateDiff, x.last_used)} ) ngày mới được sử dụng`}</p>
                                                </Popover>
                                            }>
                                                <div className='col-6'>
                                                    <Checkbox checked={x.isSelect} disabled={x.disabled}>{x.product_name}</Checkbox>
                                                    <br />
                                                </div>
                                            </Whisper>
                                        }
                                    })}
                                </Row>
                            </Container>
                        </div>
                    </Popover>
                }}
            >
                <Button appearance="subtle" className={lengthSelect.length > 0 ? "success-full" : ""}>{label}</Button>
            </Whisper>
        </>
    )
}