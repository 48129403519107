import React, { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col } from "react-bootstrap"
import Search from "./Search"
import Header from "../Header";
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth } from "date-fns"
import axiosService from "../../utils/axios.service";
import { Bar } from '@ant-design/plots';
import "./scss/report.scss"
import { Table, Spin, Tabs } from 'antd';
import async from "async"
import 'antd/dist/antd.min.css'
import StorageService from "../../utils/storage.service";
import PopupViewServices from "./PopupViewServices"
import ViewDetailServiceTvv from "./ViewDetailServiceTvv"
import ViewOrderNoTvv from "./ViewOrderNoTvv"
import Reward from "./Reward"
import ViewDetailServiceUser from "./ViewDetailServiceUser"
import ViewDetailOrderServiceUser from "./ViewDetailOrderServiceUser"
import ViewDetailOrderServiceTvv from "./ViewDetailOrderServiceTvv"

const queryParams = new URLSearchParams(window.location.search)
const type = queryParams.get("type")


const { TabPane } = Tabs;

const ReportKtvTvvOfflineDetail = () => {
    const [title, setTiltle] = useState("")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [isExport, setIsExport] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isView, setIsView] = useState(false);
    const [totalComKtv, setTotalComKtv] = useState(0);
    const [dataViewService, setDataViewService] = useState([])
    const [chart, setChart] = useState([])
    const [table, setTable] = useState([])
    const [keyword, setKeyword] = useState([])
    const [totalKtv, setTotalKtv] = useState(0)
    const [totalComTvv, setTotalComTvv] = useState(0)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const [token, setToken] = useState('')
    const [userId, setUserId] = useState(userAdmin.id)
    const [userName, setUserName] = useState(userAdmin.name)
    const [reportKtvs2, setReportKtvs2] = useState([])
    const [queryTvv, setQueryTvv] = useState([])
    const [voucherCode, setVoucherCode] = useState([])
    const [aov, setAov] = useState(0)
    const [totalOrderKtv, setTotalOrderKtv] = useState(0)
    const [orderNo, setOrderNo] = useState([])
    const [totalOrderTvv, setTotalOrderTvv] = useState(0)
    const [emulations, setEmulations] = useState({})
    const [totalPaidTarget, setTotalPaidTarget] = useState(
        {
            totalPaidUpPremium: 0,
            totalPaidPremium08: 0, 
            totalPaidPremiumFullbody: 0,
            totalPaidDauTriet: 0,
            totalPaidCentUpgrade: 0,
            totalPaidTheoVung: 0,
            totalPaidBody: 0,
            totalPaidDa: 0
        })

    useEffect(() => {
        setTiltle("Chi tiết KTV")
    }, [])
    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const hanldeStore = (x) => {
        setStore(x)
    }


    const hanldeUser = (x) => {
        setUserId(x.value)
        setUserName(x.label)
    }
    const fetchData = async (sD, eD, s) => {
        const { users, data } = await async.parallel({
            users: (cb) => {
                axiosService(`api/reports/store/users/${store}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            },
            data: (cb) => {
                axiosService(`api/mysql/report-detail-user-ktv-tvv/all?type=1&user_id=${userId}&user_name=${userName}&start=${sD}&end=${eD}&keyword=${keyword}`, "GET").then(rs => {
                    const { data } = rs
                    cb(null, data)
                })
            }
        })
        if (data.code === 200) {
            setLoading(false)
            const {totalKtv, totalComTvv, dataChart, dataTable, queryKtv, queryTvv, voucherCode, aov, totalOrderKtv, totalOrderTvv, orderNo, emulations, totalPaidTarget} = data.data
            // setChart(dataChart)
            setTable(dataTable)
            setTotalKtv(totalKtv)
            setTotalComTvv(totalComTvv)
            setReportKtvs2(queryKtv)
            setQueryTvv(queryTvv)
            setVoucherCode(voucherCode)
            setAov(aov)
            setTotalOrderKtv(totalOrderKtv)
            setTotalOrderTvv(totalOrderTvv)
            setOrderNo(orderNo)
            setEmulations(emulations)
            setTotalPaidTarget({...totalPaidTarget})
        }

    }
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(startDate, endDate, store)
    }, [startDate, endDate, store, keyword, userId])

    const config = {
        data: chart,
        isStack: true,
        xField: 'value',
        yField: 'name',
        seriesField: 'type',
        label: false,
        xAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        }
    };
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    const showPopupViewService = (e) => {
        setIsView(!isView)
        setDataViewService(e)
        let totalCom = 0
        for (let item of e) {
            totalCom += item.total
        }
        setTotalComKtv(totalCom)
    }

    const handleIsView = (e) => {
        setIsView(!isView)
    }
   
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    const onChangeKeyword = (e) => {
        setKeyword(e)
    }
    function callback(key) {
        console.log(key);
    }
    return (
        <>
            <Header/>
            <div className={`${styles.background}`}>
                <Search startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate} store={store}
                    hanldeStore={hanldeStore}
                    hanldeUser={hanldeUser}
                    title={title} type={"detail-ktv-tvv"}
                    csvData={table} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    onChangeKeyword={onChangeKeyword}
                    header={
                        [
                            { label: 'Nhân viên', key: "user_name" },
                            { label: "Số lịch đã xác nhận", key: "countBooking" },
                            { label: "Hoa hồng xác nhận lịch", key: "rose_confirm" },
                            { label: "Số khách đón tiếp", key: `countCustomer` },
                            { label: "Hoa hồng đón tiếp", key: `rose_welcome` },
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <div className="container-fluid">
                    <Spin spinning={loading} tip="Đang tải...">
                        <Row>
                            <Col xs={12} xl={12}>
                                <Row className="h-100">
                                    <Col xs={6} md={2} className="mb-2">
                                        <Tag color="green" className="w-100 p-2 h-100">
                                            <span className={styles.textTag2}>Tổng hoa hồng phục vụ: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                                                
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalKtv)}</span>
                                                    <br />
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={2} className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Thực thu tư vấn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalComTvv)}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={12} md={4}>
                                    <Row className="mh-172">
                                        <Col xs={12} md={6} className="mb-2">
                                            <Tag className=" w-100 h-100 p-2" style={{background: '#fb8a25'}}>
                                                <p className={styles.textTag3}>KẾT QUẢ MỤC TIÊU</p>
                                                <div className="d-flex justify-content-between p-2">
                                                    <div>
                                                        <p  className={styles.textTag4}>Nâng cấp Triệt lông Premium</p> 
                                                        <p  className={styles.textTag4}>Triệt lông Premium 08 vùng</p> 
                                                        <p  className={styles.textTag4}>Triệt lông Premium Fullbody</p>
                                                        <p  className={styles.textTag4}>Đầu triệt cá nhân</p>


                                                    </div>
                                                    {/*  */}
                                                    <div>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidUpPremium)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidPremium08)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidPremiumFullbody)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidDauTriet)}</p>
                                                    </div>
                                                </div>
                                            </Tag>
                                        </Col>
                                        <Col xs={12} md={6} className="mb-2">
                                            <Tag className=" w-100 h-100 p-2" style={{background: '#fb8a25'}}>
                                                <p className={styles.textTag3}>KẾT QUẢ MỤC TIÊU</p>
                                                <div className="d-flex justify-content-between p-2">
                                                    <div>
                                                        <p  className={styles.textTag4}>Cent Upgrade</p> 
                                                        <p  className={styles.textTag4}>Triệt lông theo vùng</p> 
                                                        <p  className={styles.textTag4}>Chăm sóc Body</p>
                                                        <p  className={styles.textTag4}>Chăm sóc Da</p>


                                                    </div>
                                                    {/*  */}
                                                    <div>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidCentUpgrade)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidTheoVung)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidBody)}</p>
                                                        <p  className={styles.textTag3}>{convertCurrency(totalPaidTarget.totalPaidDa)}</p>
                                                    </div>
                                                </div>
                                            </Tag>
                                        </Col>
                                    </Row>
                                    </Col>
                                    
                                    <Col xs={6} md={2} className="mb-2">
                                        <Tag color="blue" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Hoa hồng tư vấn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag}> </span>
                                                    <br />
                                                    <br />
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                        {convertCurrency(totalComTvv*0.05)}</span>
                                                    <br />

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6} md={2} className="mb-2">
                                        <Tag color="violet" className="w-100 p-2 h-100">
                                            <span className={styles.textTag3}>Tỷ lệ chốt đơn: </span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span  className={styles.textTag5}> Số đơn Pv:</span>
                                                    <span  className={styles.textTag5}> Số đơn Tv:</span>
                                                    <span className={styles.textTag5}> Tỷ lê:</span>
                    
                                                </div>
                                                <div className="ml-3">
                                                    <span className={styles.textTag}>
                                                    {totalOrderKtv}</span>
                                                    <span className={styles.textTag}>
                                                    {totalOrderTvv}</span>
                                                    <span className={styles.textTag}>
                                                        {aov} %</span>

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    {/* {voucherCode.length > 0 ?
                                        <Col xs={8} md={3} className="mb-2">
                                            <Tag color="violet" className="w-100 p-2 h-100">
                                                <span className={styles.textTag3}>Mã Voucher tư vấn </span>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        {voucherCode.map(x => (
                                                            <span className={styles.textTag} style={{fontSize: '13px'}}> {x.code} </span>
                                                        ))}
                        
                                                    </div>
                                                    <div className="ml-3">
                                                        {voucherCode.map(x => (
                                                            <span className={styles.textTag} style={{fontSize: '13px'}}>{x.count_used}/{x.max_used} </span>
                                                        ))}
    
                                                    </div>
                                                </div>
                                            </Tag>
                                        </Col>
                                    : null} */}
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Tabs defaultActiveKey="1" onChange={callback}>
                                    <TabPane tab="Chi tiết phục vụ" key="1">
                                        <ViewDetailOrderServiceUser
                                            table = {reportKtvs2}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        >
                                            
                                        </ViewDetailOrderServiceUser>
                                    </TabPane>
                                    <TabPane tab="Chi tiết đơn tư vấn" key="2">
                                        <ViewDetailOrderServiceTvv
                                            table = {queryTvv}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        >
                                            
                                        </ViewDetailOrderServiceTvv>
                                    </TabPane>
                                    <TabPane tab="Tổng quan phục vụ" key="3">
                                        <ViewDetailServiceUser
                                            table = {table.services}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        >
                                            
                                        </ViewDetailServiceUser>
                                    </TabPane>
                                    <TabPane tab="Báo cáo hàng ngày KTV" key="4">
                                        <ViewDetailServiceTvv
                                            table = {table.tvv_day}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        />
                                    </TabPane>
                                    <TabPane tab="Đơn khách còn nợ" key="5">
                                        <ViewOrderNoTvv
                                            table = {orderNo}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        />
                                    </TabPane>

                                    <TabPane tab="Thi đua" key="6">
                                        <Reward
                                            table = {emulations}
                                            startDate={startDate}
                                            endDate={endDate}
                                            userId={userId}
                                            userName={userName}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </div>
            {isView ?
               <>
                <PopupViewServices
                    show={isView}
                    close={handleIsView}
                    dataViewService={dataViewService}
                    totalComKtv={totalComKtv}
                >
                </PopupViewServices>
                </>
            : null}
        </>
    )
}
export default ReportKtvTvvOfflineDetail