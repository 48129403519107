import React, { useState, useLayoutEffect, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import Header from "./Header"
import styles from "./style.module.css"
import { Tag } from "rsuite"
import { format, startOfMonth, startOfDay, endOfDay, addDays } from "date-fns"
import axiosService from "../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { DataGrid } from '@mui/x-data-grid';
import { Column } from '@ant-design/plots';
import { Spin } from "antd"
import StorageService from "../../utils/storage.service";
import "./scss/report.scss"
import 'antd/dist/antd.min.css'
const ReportLocation = () => {
    const [title, setTiltle] = useState("Theo chi nhánh")
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(new Date())), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [receipt, setReceipt] = useState({})
    const [receiptByCash, setReceiptByCash] = useState(0)
    const [receiptByCredit, setReceiptByCredit] = useState(0)
    const [paySwipeCard, setPaySwipeCard] = useState(0)
    const [payCardMoney, setPayCardMoney] = useState(0)
    const [payLoyalty, setPayLoyalty] = useState(0)
    const [payOther, setPayOther] = useState({})
    const [dataChart, setDataChart] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const history = useHistory();

    let currentUrl = window.location.pathname
    if (currentUrl.includes('report/locations') && [3, 4].includes(userAdmin.role)) history.push('/report/daily');

    const hanldeRangeDate = (sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
    }
    const columns = [
        { field: 'id', headerName: 'STT', width: 20 },
        { field: 'name', headerName: 'Chi nhánh', width: 200, cellClassName: (params) => "text" },
        { field: 'orders', headerName: 'Số đơn hàng', type: "number", width: 140, styles: { color: "red" } },
        {
            field: 'receipt',
            headerName: 'Đã thu',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.receipt), width: 130,
            cellClassName: (params) => "text1"
        },
        {
            field: 'owed',
            headerName: 'Còn nợ',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.owed), width: 130,
            cellClassName: (params) => "text2"
        },
        {
            field: 'revenueOwe',
            headerName: 'Nợ đã thu',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.revenueOwe), width: 130,
            cellClassName: (params) => "text1"
        },
        {
            field: 'orders_thuan',
            headerName: 'Doanh số thuần',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.orders_thuan), width: 130,
            cellClassName: (params) => "text7"
        },
        {
            field: 'deposit',
            headerName: 'Trừ tài khoản',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.deposit), width: 130,
            cellClassName: (params) => "text2"
        },
        {
            field: 'gift_cash',
            headerName: 'Trừ điểm tích luỹ',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.gift_cash ?? 0), width: 120,
            cellClassName: (params) => "text3"
        },
        {
            field: 'voucher',
            headerName: 'Trừ voucher',
            type: "number",
            valueGetter: (params) => convertCurrency(params.row.voucher ?? 0), width: 130,
            cellClassName: (params) => "text2"
        },
        {
            field: 'revenue',
            headerName: 'Doanh thu',
            type: 'number',
            valueGetter: (params) => convertCurrency(params.row.revenue), width: 150,
            cellClassName: (params) => "text4"
        },
    ];
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };
    useLayoutEffect(() => {
        setLoading(true)
        fetchData(endDate, startDate)
    }, [endDate, startDate])
    const fetchData = async (eD, sD) => {
        let doneData = false
        let DoneLocation = false
        const { data, locations } = await async.parallel({
            data: (cb) => {
                axiosService(`api/reports/locations?&startDate=${sD}&endDate=${eD}`, "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        doneData = true
                        let totalReceiptAl = 0
                        let receiptByCashAl = 0
                        let receiptByCreditAl = 0
                        let paySwipeCardAl = 0
                        let payCardMoney = 0
                        let payLoyalty = 0
                        let dataPayOthers = data.data.payOther
                        for(let item of dataPayOthers.total) {
                            if (item.transaction_pay_type == "Tiền mặt") receiptByCashAl += Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Chuyển khoản") receiptByCreditAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Quẹt thẻ") paySwipeCardAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Sử dụng thẻ tiền") payCardMoney +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Điểm tích luỹ") payLoyalty += Number(item.sum_paid_amount)
                        }

                        for(let item of dataPayOthers.owed) {
                            if (item.transaction_pay_type == "Tiền mặt") receiptByCashAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Chuyển khoản") receiptByCreditAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Quẹt thẻ") paySwipeCardAl +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Sử dụng thẻ tiền") payCardMoney +=  Number(item.sum_paid_amount)
                            if (item.transaction_pay_type == "Điểm tích luỹ") payLoyalty +=  Number(item.sum_paid_amount)
                        }

                        setReceipt(data.data.totalReceipt)
                        setReceiptByCash(receiptByCashAl)
                        setReceiptByCredit(receiptByCreditAl)
                        setPaySwipeCard(paySwipeCardAl)
                        setPayCardMoney(payCardMoney)
                        setPayLoyalty(payLoyalty)
                        setPayOther(data.data.payOther)
                        cb(null, data.data.data)
                    }
                })
            },
            locations: (cb) => {
                axiosService("api/stores", "GET").then(rs => {
                    const { data } = rs
                    if (data.code == 200) {
                        DoneLocation = true
                        cb(null, data.data)
                    }
                })
            }
        })
        if (doneData && DoneLocation) {
            setLoading(false)
            Promise.all(locations.map(x => {
                const index = data.findIndex((y) => y.store_id === x.id)
                if (index >= 0) {
                    return { name: x.name_store, value: parseInt(data[index].sum_total_price) || 0 }
                } else {
                    return {
                        name: x.name_store, value: 0
                    }
                }
            })).then(rs => {
                setDataChart(rs)
            })
            Promise.all(locations.map((x, i) => {
                const index = data.findIndex((y) => y.store_id === x.id)
                if (index >= 0) {
                    let sum_upsale_money_owed = data[index].sum_upsale_money_owed ?? 0;
                    let sum_upsale_total_price_before = data[index].sum_upsale_total_price_before ?? 0;
                    let revenue_owe = data[index].sum_owd_paid_amount ?? 0
                    return {
                        id: i+1, 
                        name: x.name_store,
                        orders: Number(data[index].total_orders ?? 0) - Number(data[index].total_upsale_orders?? 0)  || 0,
                        orders_thuan: Number(data[index].sum_total_price ?? 0) - Number(data[index].sum_upsale_total_price_before ?? 0) + Number(data[index].sum_total_amount_using_upsale ?? 0) ,
                        receipt: Number(data[index].sum_paid_amount ?? 0) + Number(revenue_owe),
                        deposit: Number(data[index].sum_deposit_total) || 0,
                        gift_cash: Number(data[index].sum_gift_cash_total) || 0,
                        owed: Number(data[index].sum_money_owed) - Number(sum_upsale_money_owed)  || 0,
                        revenueOwe: Number(revenue_owe),
                        voucher:  Number(data[index].sum_total_price_before) - Number(data[index].sum_total_price) || 0,
                        revenue: Number(data[index].sum_total_price_before) - Number(sum_upsale_total_price_before) || 0,
                    }
                } else {
                    return {
                        id: i+1, name: x.name_store, users: 0, orders: 0, receipt: 0, deposit: 0, gift_cash: 0,
                        owed: 0, revenue: 0, revenueOwe: 0, orders_thuan: 0
                    }
                }
            })).then(rs => {
                setRows(rs)
            })

        }

    }
    const config = {
        data: dataChart,
        xField: 'name',
        yField: 'value',
        xAxis: {
            label: {
                autoRotate: true,
            },
        },
        slider: {
            start: 0,
            end: 1,
        },
        minColumnWidth: 20,
        maxColumnWidth: 20,
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        tooltip: {
            formatter: (data) => {
                return {
                    name: data.name,
                    value: convertCurrency(data.value)
                };
            },
        },
        color: 'red'
    };
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    return (
        <>
            <div className={`${styles.background} report-page`}>
                <Header startDate={startDate} endDate={endDate}
                    hanldeRangeDate={hanldeRangeDate}
                    title={title} type={"locations"}
                    csvData={rows} isExport={isExport}
                    hanldeExportData={hanldeExportData}
                    header={
                        [
                            { label: 'Chi nhánh', key: "name" },
                            { label: "Số đơn hàng", key: "orders" },
                            { label: "Đã thu", key: "receipt" },
                            { label: "Trừ tài khoản", key: "deposit" },
                            { label: "Trừ điểm thưởng", key: "gift_cash" },
                            { label: "Trừ voucher", key: "voucher" },
                            { label: "Còn nợ", key: "owed" },
                            { label: "Nợ đã thu", key: "revenueOwe" },
                            { label: "Doanh thu", key: "revenue" }
                        ]
                    }
                    userAdmin={userAdmin}
                />
                <div className="container-fluid">
                    <Spin spinning={loading}>
                        <Row className="pt-2">
                            <Col xs={12} md={5}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Tag color="blue" className="w-100 h-100 p-2">
                                            <span className={styles.textTag2}>Tổng tiền đã thu</span>
                                            <div className="d-flex justify-content-between">
                                            <div>
                                            <span className={styles.textTag}> </span>
                                                    <br />

                                                    <span className={styles.textTag3}>Doanh số thuần</span>
                                                </div>
                                                <div>
                                                    <span className={styles.textTag}>{convertCurrency(Number(receipt?.revenueByOrder + Number(receipt?.revenueByOwed)))}</span>
                                                    <br />
                                                    <span className={styles.textTag}> {convertCurrency(Number(receipt.totalByOrder?.sum_total_price ?? 0) + Number(receipt.totalByOrder?.sum_total_amount_using_upsale ?? 0) - Number(receipt.totalByOrderUpsale?.sum_total_price ?? 0))}</span>

                                                </div>

                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Tag color="cyan" className="w-100 h-100 p-2">
                                            <span className={styles.textTag2}>Nợ Khách Hàng</span>
                                            <div className="d-flex justify-content-between">
                                            <div>
                                                    <span className={styles.textTag3}>Nợ mới </span>

                                                    <span className={styles.textTag3}>Nợ Đã thu</span>
                                                </div>
                                                <div>
                                                    <span className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_money_owed) - Number(receipt?.totalByOrderUpsale?.sum_money_owed))}</span>
                                                    <span className={styles.textTag}>{convertCurrency(Number(receipt?.revenueByOwed))}</span>
                                                </div>

                                            </div>
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12} md={6}>
                                        <Tag color="violet" className="w-100 h-100 p-2">
                                            <span className={styles.textTag2}>Tiền thu các phương thức</span>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <span className={styles.textTag3}>Tiên mặt</span>
                                                    <span className={styles.textTag3}>Quẹt thẻ</span>
                                                    <span className={styles.textTag3}>Chuyển khoản</span>
                                                </div>
                                                <div>
                                                    
                                                    <span className={styles.textTag}>{convertCurrency(receiptByCash)}</span>
                                                    <span className={styles.textTag}>{convertCurrency(paySwipeCard)}</span>
                                                    <span className={styles.textTag}>{convertCurrency(receiptByCredit)}</span>

                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Tag color="aquamarine" className={`${styles.tag1} w-100 h-100 p-2`}>
                                            <p className={styles.textTag2}>Khoản không tính thực thu</p>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <p className={styles.textTag3}>Trừ tài khoản</p>
                                                    <p className={styles.textTag3}>Trừ điểm tích luỹ</p>
                                                    <p className={styles.textTag3}>Trừ voucher</p>
                                                </div>
                                                <div>
                                                    <p className={styles.textTag}>{convertCurrency(payCardMoney)}</p>
                                                    <p className={styles.textTag}>{convertCurrency(payLoyalty)}</p>
                                                    <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price_before) - Number(receipt?.totalByOrder?.sum_total_price))}</p>
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                    {/* <Col xs={3}>
                                        <Tag color="orange" className="w-100 h-100 mt-2 p-2">
                                            <span className={styles.textTag2}>Trừ trong thẻ</span>
                                            <div className="d-flex justify-content-start">
                                                <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_deposit_total)}</span>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={3}>
                                        <Tag style={{background: "darkgreen"}} className="w-100 h-100 mt-2 p-2">
                                            <span className={styles.textTag2}>Trừ điểm thưởng</span>
                                            <div className="d-flex justify-content-start">
                                                <span className={styles.textTag}>{convertCurrency(receipt?.totalByOrder?.sum_gift_cash_total)}</span>
                                            </div>
                                        </Tag>
                                    </Col>
                                    <Col xs={6}>
                                        <Tag color="red" className=" w-100 h-100 mt-2 p-2">
                                            <p className={styles.textTag2}>NỢ PHẢI THU</p>
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price) - Number(receipt?.revenueByOrder) - Number(receipt?.totalByOrder?.sum_deposit_total) - Number(receipt?.totalByOrderUpsale?.sum_money_owed))}</p>
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col> */}
                                </Row>
                                {/* <Row className="mt-2">
                                    <Col xs={6}>
                                        <Tag color="orange" className=" w-100 h-100 mt-2 p-2">
                                            <p className={styles.textTag2}>TRỪ VOUCHER</p>
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price_before) - Number(receipt?.totalByOrder?.sum_total_price))}</p>
                                                    <p className={styles.textTag}>{convertCurrency(Number(receipt?.totalByOrder?.sum_total_price) - Number(receipt?.revenueByOrder) - Number(receipt?.totalByOrder?.sum_deposit_total) - Number(receipt?.totalByOrder?.sum_gift_cash_total))}</p>
                                                </div>
                                            </div>
                                        </Tag>
                                    </Col>
                                </Row> */}
                                <Row className="pt-2 mt-2">
                                    <Col xs={12} className="hide-mobile">
                                        <div style={{ backgroundColor: "white" }} className="p-2">
                                            <Column {...config} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={7}>
                                <div style={{ backgroundColor: "white" }} className="p-2">
                                    <div className="mb-2">
                                        <strong>Bảng dữ liệu chi tiết</strong>
                                    </div>
                                    <div style={{ height: 590, width: '100%' }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            rowsPerPageOptions={[6]}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </div>
        </>
    )
}
export default ReportLocation